import React from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import background from 'assets/pictures/background.jpg'
import useTitle from '../../../utils/customHook/useTitle'
import App from '../components/App'

const useStyles = makeStyles(() => ({
    title: {
        color: 'white',
        fontWeight: 'bold',
        textShadow: 'black 0px 2px 5px',
        textAlign: 'center',
        fontSize: '5vmin',
        marginBottom: '1rem',
    },
    logoAquasys: {
        maxWidth: '25%',
        height: 'auto',
    },
    aquasysLogo: {
        width: '60%',
        height: 'auto',
        maxWidth: '150px',
    },
    subtitle: {
        color: 'white',
        fontSize: '0.9em',
        marginRight: '5px',
    },
    background: {
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
    },
}))

const Home = () => {
    const classes = useStyles()

    const aquasysLogoUrl = 'https://www.aquasys.fr/wp-content/uploads/2022/07/Logo aQuasys_blanc.svg'

    useTitle(() => [
        {
            title: 'Accueil',
            href: 'home',
        },
    ], [])

    return (
        <div className={classes.background}>
            <App />
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                sx={{
                    padding: '8px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -65%)',
                    width: '75%',
                    backgroundColor: 'rgba(14 14 14 / 0.55)',
                    borderRadius: '2px',
                }}
            >
                <Grid item container justifyContent='center' xs={12}>
                    <img src={aquasysLogoUrl} alt='logo_aquasys' className={classes.logoAquasys} />
                </Grid>
                <Grid item xs={12} lg={10} xl={8}>
                    <div className={classes.title}>Bienvenue sur le Socle Applicatif d'Aquasys !</div>
                </Grid>
                <Grid item container justifyContent='center' alignItems='center' xs={12}>
                    <div className={classes.subtitle}>
                        Un service développé par
                    </div>
                    <img src={aquasysLogoUrl} alt='Logo aquasys' className={classes.aquasysLogo} />
                </Grid>
            </Grid>
        </div>
    )
}

export default Home
