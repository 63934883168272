import { path } from './basepath'

// const localApi = 'http://localhost:8000/'

export default {
    login: () => `${path}login`,
    logout: () => `${path}logout`,
    resetPassword: () => `${path}reset`,
    verifyResetCode: () => `${path}verifyResetCode`,
    ping: pathToPing => `https://${pathToPing}/api/mobile/ping`,
    log: {
        error: () => `${path}log/error`,
        info: () => `${path}log/info`,
        debug: () => `${path}log/debug`,
    },
    contact: {
        getChat: (login, dest) => `${path}user/messagesDest/conv/${login}/${dest}`,
        getMessage: (id) => `${path}user/messages/${id}`,
        sendMessage: () => `${path}user/messages`,
        defConv: () => `${path}user/messagesDest`,
        getMessagesOf: (login) => `${path}user/messages/login/${login}`,
    },
    cms: {
        getAll: () => `${path}cms`,
        insert: () => `${path}cms`,
        get: id => `${path}cms/${id}`,
        delete: id => `${path}cms/${id}`,
        update: id => `${path}cms/${id}`,
        categories: () => `${path}cms/category`,
        getByCategory: (id) => `${path}cms/${id}/category`,
    },
    referencial: {
        contact: id => `${path}referencial/contact/${id}`,
        contacts: () => `${path}referencial/contact`,
        city: code => `${path}city/${code}`,
        cities: () => `${path}city/`,
        contributor: id => `${path}referencial/contributor/${id}`,
        contributors: () => `${path}referencial/contributor/`,
        sandreCodes: () => `${path}sandre/`,
        watersheds: () => `${path}watershed/`,
        aquifers: () => `${path}referencial/aquifer/`,
    },
    cgu: {
        getAll: () => 'https://www.aquasys.fr/wp-json/wp/v2/pages/3995',
        getDateValidCGU: (login) => `${path}user/statistic/cgu/${login}`,
        sendDateCgu: () => `${path}statisticApplication/user/cgu`,
    },
    user: {
        get: (login) => `${path}user/${login}`,
        getAll: () => `${path}user/`,
        delete: (login) => `${path}user/${login}`,
        getKeyFigures: (login) => `${path}user/keyfigures/${login}`,
        getStatistics: (login) => `${path}user/statistic/${login}`,
        getStationStatistics: (login) => `${path}user/statistic/station/${login}`,
        getCmsStatistics: (login) => `${path}user/statistic/cms/${login}`,
        getUsageStatistics: (login) => `${path}user/statistic/usage/${login}`,
        getUserCGUDate: (login) => `${path}user/statistic/cgu/${login}`,
        postUserCGUDate: () => `${path}statisticApplication/user/cgu`,
        userStations: (login) => `${path}user/station/${login}`,
        put: () => `${path}user`,
        filters: () => `${path}user/filters/station`,
        filterResults: (module, filterCode) =>
            `${path}user/filters/station/${module}/${filterCode}/execute`,
        getBookmarks: () => `${path}user/bookmarks`,
        setBookmark: () => `${path}user/bookmark`,
        settings: () => `${path}user/parameters`,
        applicationSettings: () => `${path}user/parameter/sieau`,
        saveSettings: (login) => `${path}user/parameters/${login}`,
        setting: () => `${path}user/parameter`,
        habilitations: (login = '') => {
            if (login) {
                return `${path}user/habilitations/${login}`
            }
            return `${path}user/habilitations`
        },
        themeAssign: () => `${path}user/theme`,
        assignedThemesLayers: () => `${path}user/theme/all`,
        applicationHabilitations: () => `${path}user/applicationHabilitations`,
        sieauParameters: (parameter) => `${path}user/parameter/sieau/${parameter}`,
        userView: () => `${path}user/view/`,
        genericUserView: () => `${path}user/view/generic/`,
        changePassword: () => `${path}user/password`,
        updatePassword: (login) => `${path}user/password/${login}`,
    },
    licences: {
        licences: () => `${path}licence`,
        licence: (id) => `${path}licence/${id}`,
    },
    contactsType: {
        contactsType: () => `${path}contactype`,
        contactType: (id) => `${path}contactype/${id}`,
    },
    contacts: {
        contacts: () => `${path}contact`,
        contact: (id) => `${path}contact/${id}`,
    },
    customersType: {
        customersType: () => `${path}customertype`,
        customerType: (id) => `${path}customertype/${id}`,
    },
    customers: {
        customers: () => `${path}customer`,
        customer: (id) => `${path}customer/${id}`,
        customerGetLicence: (id) => `${path}customer/${id}/licence`,
        customerLicence: (id, idLicence) => `${path}customer/${id}/${idLicence}`,
    },
}
