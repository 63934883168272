import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { Grid, LinearProgress } from '@mui/material'
import HomeAction from './actions/HomeAction'
import { getLoginPassword } from '../../utils/ActionUtils'
import { withStyles } from '@mui/styles'
import logoAquasys from 'assets/pictures/logo_aquasys.jpg'
import bg1 from 'assets/pictures/manu_bg1.jpg'
import bg2 from 'assets/pictures/manu_bg2.jpg'
import packageJson from '../../../package.json'
import { Link } from 'react-router-dom'
import { cmsPath } from 'conf/basepath'
import { isRunningApp } from '../../utils/LocalStorageUtils'
import DtoUser from 'pages/online/account/dto/DtoUser'
import DtoCMSEvent from 'pages/online/cms/dto/DtoCMSEvent'
import ToastrAction from 'toastr/actions/ToastrAction'
import AccountAction from 'pages/online/account/actions/AccountAction'
import { SIEAU_TOKEN } from './constants/HomeConstants'
import DtoApplicationSettings from './dto/DtoApplicationSettings'
import { InputRow } from 'components/styled/Inputs'
import { PrimaryButton } from 'components/styled/Buttons'
import ModalCGU from './components/ModalCGU'
import ModalUpdatePassword from 'pages/online/account/components/ModalUpdatePassword'

const styles = () => ({
    logo: {
        height: 60,
        width: 'auto',
    },
    clickable: {
        cursor: 'pointer',
    },
    loginCard: {
        height: '60%',
        width: '25%',
        backgroundColor: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '2rem',
        borderRadius: '4px',
    },
})

class Login extends Component {
    constructor(props) {
        super(props)
        const credentials = getLoginPassword() || ['', '']
        this.state = {
            login: credentials[0],
            password: credentials[1],
            openModal: false,
            dataLoaded: false,
            loginError: false,
            passwordError: false,
            openModalPassword: false,
            images: [],
            cmsCGUDate: 0,
            newMdp: '',
            mdpConfirmation: '',
            settingsLoaded: false,
            backgroundUrl: '',
            loading: false,
        }
    }

    componentDidMount() {
        this.props.fetchApplicationSettings().then(() => {
            const { applicationSettings } = this.props
            const applicationName = applicationSettings.find(({ parameter }) => parameter === 'applicationName') || {}
            if (applicationName.value) {
                document.title = applicationName.value
            }
            this.setState({ settingsLoaded: true })
        })
        this.props.fetchCMSHome(4).then((cms) => {
            if (cms && cms.document && cms.document.length) {
                const backgroundUrl = cms.document[0].name
                this.setState({ backgroundUrl: cmsPath + backgroundUrl })
            }
        })
        this.props.fetchCMSLogo(3).then(() => {
            const { cmsLogo } = this.props
            if (cmsLogo && cmsLogo.document && cmsLogo.document.length) {
                const logoUrl = cmsLogo.document[0].name
                const url = cmsPath + logoUrl
                this.setState({ logoUrl: url })
            }
        })
        this.getCGU()
        this.getImages()
    }

    getCGU = () => {
        this.props.getCGUFromCms().then(() => {
            const { cmsCGU } = this.props
            const cmsCGUDate = Math.max(cmsCGU.map(({ updateDate }) => updateDate), 0)
            this.setState({
                cmsCGUDate,
                dataLoaded: true,
            })
        })
    }

    toggleModalPassword = () => {
        const { openModalPassword } = this.state
        this.setState({ openModalPassword: !openModalPassword })
    }

    toggleModalCGU = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    onUpdatePassword = () => {
        const { newMdp, mdpConfirmation, login } = this.state
        const { accountUser, applicationSettings } = this.props
        if (
            newMdp !== null &&
            newMdp.length &&
            mdpConfirmation !== null &&
            mdpConfirmation.length
        ) {
            if (newMdp === mdpConfirmation) {
                const regex = RegExp(applicationSettings.find((s) => s.parameter === 'passwordPolicy').value)
                const regexHelp = applicationSettings.find((s) => s.parameter === 'securityPasswordDescription').value
                if (regex.test(newMdp)) {
                    if (newMdp !== login) {
                        this.props.updatePassword(newMdp, accountUser.resetPassword).then(() => {
                            this.props.login(login, newMdp).then(() => {
                                this.toggleModalPassword()
                                this.checkCGU()
                            })
                        })
                    } else {
                        this.props.error('Les mots de passe doivent être différents', true)
                    }
                } else {
                    this.props.error(regexHelp, true)
                }
            } else {
                this.props.error('Les mots de passe ne sont pas indentiques', true)
            }
        }
    }

    getImages = () => {
        this.props.getCmsPartenaire().then(() => {
            const { cms } = this.props
            const list = []
            cms.map((c) => {
                if (c.cmsDocument[0]) {
                    list.push({
                        link: c.link,
                        docName: c.cmsDocument[0].name,
                    })
                }
            })
            this.setState({
                images: list,
            })
        })
    }

    onSubmit = () => {
        const { login, password, loading } = this.state
        if (!login) {
            this.setState({ loginError: true })
        }
        if (!password) {
            this.setState({ passwordError: true })
        }
        if (password && login && !loading) {
            this.setState({ loading: true })
            this.props.login(login, password, this.onLogin).then(() => this.setState({ loading: false }))
        }
    }

    onLogin = () => {
        const { accountUser } = this.props
        if (!accountUser.resetPassword) {
            this.checkCGU()
        } else if (accountUser.resetPassword.length > 4) {
            this.setState({ openModalPassword: true })
        } else {
            this.props.logout()
            this.props.warning(
                'Modifier le mot de passe',
                true,
            )
        }
    }
    checkCGU = () => {
        const { login } = this.state
        this.props.getDateValidCGU(login).then(() => {
            const { dateValidCgu } = this.props
            const { cmsCGUDate } = this.state
            const dateUser = new Date(dateValidCgu)
            const dateCGU = new Date(cmsCGUDate)
            if (localStorage.getItem(SIEAU_TOKEN)) {
                if (dateUser.getTime() < dateCGU.getTime()) {
                    this.toggleModalCGU()
                } else if (!dateUser.getTime()) {
                    this.toggleModalCGU()
                } else {
                    this.props.push('/home')
                }
            }
        })
    }

    sendDateValidCGU = () => {
        const { login } = this.state
        const eventType = 'CGU'
        const module = 'CGU'
        const date = new Date()
        const postDateCGU = { login, eventType, module, version: date }
        this.props.sendDateCgu(postDateCGU).then(() => {
            this.toggleModalCGU()
            this.props.push('/home')
        })
    }

    onRefuseCGU = () => {
        this.props.logout()
        this.toggleModalCGU()
    }

    openWebSite = (link) => {
        if (!isRunningApp() && link) {
            window.open(link, '_blank')
        }
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onSubmit()
        }
    }

    render() {
        const { classes, cmsCGU } = this.props
        const { login, loginError, password, passwordError, loading, dataLoaded, openModal, openModalPassword, settingsLoaded } = this.state

        return (
            <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                <Grid item xs={6}>
                    <img src={bg1} width='100%' height='100%' style={{ objectFit: 'cover' }} />
                </Grid>
                <div className={classes.loginCard}>
                    <Grid
                        container
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ height: '100%' }}
                    >
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <h1 style={{ fontSize: '24px', margin: 0 }}>L'équipe Aquasys vous invite à vous connecter</h1>
                        </Grid>
                        <Grid item xs={12}>
                            <InputRow
                                id='login'
                                label={'Id'}
                                type='text'
                                value={login}
                                onChange={(e) => this.setState({ login: e.target.value, loginError: false })}
                                variant='outlined'
                                error={loginError}
                                helperText={loginError && 'Veuillez remplir ce champ s\'il vous plait'}
                                onKeyDown={(e) => this.onKeyDown(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputRow
                                id='login'
                                label={'Mot de passe'}
                                type='password'
                                value={password}
                                onChange={(e) => this.setState({ password: e.target.value, passwordError: false })}
                                variant='outlined'
                                error={passwordError}
                                helperText={passwordError && 'Veuillez remplir ce champ s\'il vous plait'}
                                onKeyDown={(e) => this.onKeyDown(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {!loading ? (
                                <PrimaryButton onClick={this.onSubmit}>
                                    {'Se connecter'}
                                </PrimaryButton>
                            ) : (
                                <LinearProgress />
                            )}
                        </Grid>
                        <Grid item xs={12} container justifyContent='center'>
                            <Link to='/reset' style={{ textAlign: 'center' }}>
                                {'Mot de passe oublié ?'}
                            </Link>
                        </Grid>
                        <Grid item xs={12}
                            style={{ height: '60px' }}
                        >
                            <Grid
                                container
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                style={{ height: '100%', marginTop: '8px', textAlign: 'center' }}
                            >
                                <Grid item xs={12}>
                                    <img src={logoAquasys} alt='' className={`${classes.logo} ${classes.clickable}`} onClick={() => this.openWebSite('https://www.aquasys.fr/')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <span>v{packageJson.version}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Grid item xs={6}>
                    <img src={bg2} width='100%' height='100%' style={{ objectFit: 'cover' }} />
                </Grid>
                {dataLoaded && (
                    <ModalCGU
                        open={openModal}
                        cgu={cmsCGU}
                        onRefuse={this.onRefuseCGU}
                        onValidate={this.sendDateValidCGU}
                    />
                )}
                {settingsLoaded && openModalPassword && (
                    <ModalUpdatePassword
                        open={openModalPassword}
                        toggleModal={this.toggleModalPassword}
                        handleChangeNewMdp={(e) => this.setState({ newMdp: e.target.value })}
                        handleChangeConfirmation={(e) => this.setState({ mdpConfirmation: e.target.value })}
                        onSavePassword={this.onUpdatePassword}
                        firstLogin
                    />
                )}
            </Grid>
        )
    }
}

const mapDispatchToProps = {
    login: HomeAction.login,
    logout: HomeAction.logout,
    fetchApplicationSettings: HomeAction.fetchApplicationSettings,
    fetchCMSHome: HomeAction.fetchCMSHome,
    fetchCMSLogo: HomeAction.fetchCMSLogo,
    getCGUFromCms: HomeAction.getCGUFromCms,
    getCmsPartenaire: HomeAction.getCmsPartenaire,
    sendDateCgu: HomeAction.sendDateCgu,
    updatePassword: HomeAction.updatePassword,
    getDateValidCGU: HomeAction.getDateValidCGU,
    fetchUser: AccountAction.fetchUser,
    warning: ToastrAction.warning,
    error: ToastrAction.error,
    push,
}

const mapStateToProps = (store) => {
    return {
        dateValidCgu: store.HomeReducer.dateValidCgu,
        cms: store.HomeReducer.cms,
        cmsCGU: store.HomeReducer.cmsCGU,
        cmsLogo: store.HomeReducer.cmsLogo,
        accountUser: store.AccountReducer.accountUser,
        applicationSettings: store.HomeReducer.applicationSettings,
    }
}

Login.propTypes = {
    push: PropTypes.func,
    login: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.object),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoApplicationSettings)),
    dateValidCgu: PropTypes.string,
    accountUser: PropTypes.instanceOf(DtoUser),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    cmsCGU: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    cmsLogo: PropTypes.instanceOf(DtoCMSEvent),
    fetchApplicationSettings: PropTypes.func,
    fetchCMSHome: PropTypes.func,
    fetchCMSLogo: PropTypes.func,
    getCGUFromCms: PropTypes.func,
    updatePassword: PropTypes.func,
    getCmsPartenaire: PropTypes.func,
    fetchUser: PropTypes.func,
    getDateValidCGU: PropTypes.func,
    sendDateCgu: PropTypes.func,
    logout: PropTypes.func,
    warning: PropTypes.func,
    error: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
