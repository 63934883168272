import { styled } from '@mui/styles'
import { darkBlue } from 'utils/constants/ColorTheme'

const StyledFieldSet = styled('fieldset')({
    borderRadius: '5px',
    border: 'solid 1px grey',
    padding: '10 16',
    backgroundColor: 'white',
    margin: '10 0 0 0',
    minHeight: '150px',
    // rowGap: '500px',
    // columnGap: '500px',
})

const StyledLegend = styled('legend')({
    backgroundColor: 'white',
    border: 'solid grey 1px',
    borderRadius: '5px',
    padding: '0 10',
    color: darkBlue,
    fontWeight: 'bold',
})

const StyledSpan = styled('span')({
    lineheight: '12px',
    fontSize: '16px',
})

export { StyledFieldSet, StyledLegend, StyledSpan }