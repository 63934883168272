import PropTypes from 'prop-types'
import { hasValue } from './NumberUtil'
import { substringText } from './StringUtil'

const $ = window.$

const arrayOf = (dto) => {
    return PropTypes.arrayOf(PropTypes.instanceOf(dto))
}

const instanceOf = (dto) => {
    return PropTypes.instanceOf(dto)
}

const objectOf = (dto) => {
    return PropTypes.objectOf(dto)
}
const getObjectLabel = (obj, labelName, defaultReturn='') => {
    if (obj) {
        if (hasValue(labelName) && obj[labelName]) {
            return obj[labelName]
        }
        if (obj.name) {
            return obj.name
        }
        if (obj.label) {
            return obj.label
        }
        if (obj.title) {
            return obj.title
        }
    }
    return defaultReturn
}

const createIndex = (tab, keyToUse) => {
    const res = {}
    if (keyToUse) {
        if (tab.length) {
            tab.forEach(o => {
                res[o[keyToUse]] = o
            })
            return res
        }
        return {}
    }
    if (tab.length) {
        tab.forEach(o => {
            res[o.id] = o
        })
        return res
    }
    return {}
}

const getLabel = (propList, code, labelName, key = 'code', defaultReturn='') => {
    const found = propList.find(elem => elem[key] == code)
    if (found) {
        if (labelName && found[labelName]) {
            return found[labelName]
        }
        if (found.name) {
            return found.name
        }
        if (found.label) {
            return found.label
        }
        if (found.title) {
            return found.title
        }
    }
    return defaultReturn
}

const filterHeaders = (headers, filter) => {
    return headers.filter(h => !filter.includes(h))
}

const getComponentWithId = (id) => {
    const dom = $(id)[0]
    if (!dom) {
        return null
    }
    let internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactInternalInstance$'))]
    if (!internalInstance) {
        internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactFiber$'))]
        if (!internalInstance) {
            return null
        }
    }
    return internalInstance._debugOwner ? internalInstance._debugOwner.stateNode : internalInstance.return.stateNode
}

const deleteKeys = (obj, tab = []) => {
    return Object.keys(obj).filter(k => !tab.find(o => o === k)).reduce((acc, val) => {
        const r = {}
        r[val] = obj[val]
        return Object.assign({}, acc, r)
    }, {})
}

const removeNullKeys = (obj) => {
    return Object.keys(obj).filter(k => !(obj[k] === undefined || obj[k] === null)).reduce((acc, key) => {
        const r = {}
        r[key] = obj[key]
        return Object.assign({}, acc, r)
    }, {})
}


const getLabelTruncate = (label = '', code = '', nbChar = 25) => code ? label ? `${substringText(label, nbChar)} [${code}]` : `[${code}]` : ''

const getTruncate = (text, shortNumber = 50) => {
    if (text) {
        if (text.length > shortNumber) {
            return `${text.substring(text, shortNumber)}...`
        }
        return text
    }
    return ''
}

const updateIfExists = (previousList, newOne, key) => {
    const idx = previousList.findIndex(elem => elem[key] === newOne[key])
    if (idx !== -1) {
        previousList[idx] = newOne
        return previousList
    }
    return previousList
}

export {
    deleteKeys,
    arrayOf,
    instanceOf,
    getLabel,
    filterHeaders,
    getComponentWithId,
    removeNullKeys,
    getObjectLabel,
    createIndex,
    objectOf,
    getLabelTruncate,
    updateIfExists,
    getTruncate,
}

