import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Card, Modal, TextField } from '@mui/material'
import { PrimaryButton } from 'components/styled/Buttons'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 1),
        margin: '10px auto',
        bottom: 'inherit',
        height: 'auto',
        width: '90vw',
        overflowY: 'auto',
    },
}))

const ModalUpdatePassword = ({
    open,
    toggleModal,
    handleChangeMdp,
    handleChangeNewMdp,
    handleChangeConfirmation,
    onSavePassword,
    firstLogin = false,
    title,
    label,
    noMdp = false,
}) => {
    const classes = useStyles()

    return (
        <Modal
            open={open}
            onClose={toggleModal}
        >
            <Card className={classes.paper} style={{ padding: '14px' }}>
                {firstLogin ? (
                    <Fragment>
                        <h2>{title || 'Première connection'}</h2>
                        <p>{label || 'Changez votre mot de passe temporaire'}</p>
                    </Fragment>
                ) : !noMdp ? (
                    <Fragment>
                        <h2>{'Changez votre mot de passe'}</h2>
                        <TextField
                            item='true'
                            id='password'
                            label={'Mot de passe actuel'}
                            type='password'
                            onChange={handleChangeMdp}
                            variant='outlined'
                            style={{ width: '100%', marginTop: '15px' }}
                        />
                    </Fragment>
                ) : ''}
                <TextField
                    item='true'
                    id='password'
                    label={'Nouveau mot de passe'}
                    type='password'
                    onChange={handleChangeNewMdp}
                    variant='outlined'
                    style={{ width: '100%', marginTop: '15px' }}
                />
                <TextField
                    item='true'
                    id='password'
                    label={'Confirmation'}
                    type='password'
                    onChange={handleChangeConfirmation}
                    variant='outlined'
                    style={{ width: '100%', marginTop: '15px' }}
                />
                <PrimaryButton onClick={onSavePassword} margintop>
                    {'Modifier le mot de passe'}
                </PrimaryButton>
                <PrimaryButton
                    reverse
                    variant='contained'
                    onClick={toggleModal}
                    marginTop
                >
                    {'Annuler'}
                </PrimaryButton>
            </Card>
        </Modal>
    )
}

ModalUpdatePassword.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onSavePassword: PropTypes.func.isRequired,
    handleChangeMdp: PropTypes.func,
    handleChangeNewMdp: PropTypes.func,
    handleChangeConfirmation: PropTypes.func,
    firstLogin: PropTypes.bool,
    title: PropTypes.string,
    label: PropTypes.string,
    noMdp: PropTypes.bool,
}

export default ModalUpdatePassword