import Card from 'components/card/Card'
import React, { useState, useEffect } from 'react'
import { Grid, Icon } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import '../../css/table.css'
import useTitle from '../../utils/customHook/useTitle'
import App from 'pages/online/components/App'
import VirtualizedTable from '../../components/datatable/virtualizedTable/VirtualizedTable'
import ContactTypePopin from 'components/popin/ContactTypePopin'
import ContactTypeAction from 'licence/ContactTypeAction'
import PopinToConfirm from '../../components/popin/ValidationPopin'
import { isUndefined } from 'lodash'

const ListContactTypePanel = () => {
    const dispatch = useDispatch()

    const [isOpen, setOpen] = useState(false)

    const [isOpenValidationPopin, setOpenValidationPopin] = useState(false)

    const [idContactTypeToDelete, setIdContactTypeToDelete] = useState()

    useTitle(() => [{
        title: 'Liste des types de contacts',
        href: 'ListContactType',
    }], [])

    useEffect(() => {
        dispatch(ContactTypeAction.fetchContactsType())
    }, [dispatch])

    const { contactsType } = useSelector(
        (store) => ({
            contactsType: store.ContactTypeReducer.contactsType,
        }),
        shallowEqual
    )

    const [contactType, setContactType] = useState({})

    const deleteContactType = () => {
        if (!isUndefined(idContactTypeToDelete)) {
            dispatch(ContactTypeAction.deleteContactType(idContactTypeToDelete))
                .then(() => dispatch(ContactTypeAction.fetchContactsType()))
        }
    }

    const updateContactType = (contactTypeRow) => {
        setContactType(contactTypeRow)
        setOpen(true)
    }

    const openContactTypeModal = () => {
        setContactType({})
        setOpen(true)
    }

    const openPopinToConfirmDeletion = (elementToDelete) => {
        setIdContactTypeToDelete(elementToDelete.id)
        setOpenValidationPopin(true)
    }

    const data = contactsType.map((el) => {
        return {
            delete: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer', color: 'red' }} title={'Supprimer'} onClick={() => {
                openPopinToConfirmDeletion(el)
            }}
            >delete</Icon> },
            edit: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer' }} title={'Modifier'} onClick={() => {
                updateContactType(el)
            }}
            >edit</Icon> },
            name: { value: el.name },
        }
    })

    return (
        <Grid>
            <App handleClick={openContactTypeModal} data={contactsType}/>
            {contactsType.length && (
                <>
                    <Card cardStyle={{ marginTop: window.innerHeight*0.1 }} maxWidth={window.innerWidth*0.4}>
                        <VirtualizedTable style={{ marginTop: '10px' }} rowHeight={30} columnWidth={200} headers={['delete', 'edit', 'name']} data={data}/>
                    </Card>

                    <ContactTypePopin
                        isOpen={isOpen}
                        title={contactType.id && 'Modifier le type de contact' || 'Ajouter un type de contact'}
                        closePopin={() => setOpen(false)}
                        contactType={contactType}
                    />

                    <PopinToConfirm
                        onValidate={deleteContactType}
                        isOpen={isOpenValidationPopin}
                        closePopin={() => setOpenValidationPopin(false)}
                        title={'Confirmer la suppression'}
                        content={'Êtes-vous sûr de vouloir supprimer ce type de contact ?'}
                    />
                </>
            )}
        </Grid>
    )
}

ListContactTypePanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    close: PropTypes.func,
}

export default ListContactTypePanel