import React, { useCallback, useEffect, useState } from 'react'
import { DialogMUI, DialogTitleMUI, DialogContentMUI, DialogActionsMUI } from 'components/styled/Dialog'
import { Button, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import ContactAction from 'licence/ContactAction'
import { useDispatch } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'
import Input from '../forms/Input'
import { StyledFieldSet } from '../styled/StyledElement'
import Textarea from '../forms/Textarea'
import moment from 'moment'


const ContactPopin = ({
    title = '',
    isOpen = false,
    contact = {},
    closePopin = () => {},
}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState()

    const [address, setAddress] = useState()

    const [phone, setPhone] = useState()

    const [fax, setFax] = useState()

    const [cellphone, setCellphone] = useState()

    const [phoneOffice, setPhoneOffice] = useState()

    const [mail, setMail] = useState()

    const [contactTitle, setContactTitle] = useState()

    const [comment, setComment] = useState()

    const [civility, setCivility] = useState()

    const [updateDate, setUpdateDate] = useState()

    const [countryCode, setCountryCode] = useState()

    const [contactFunction, setContactFunction] = useState()

    const [city, setCity] = useState()

    const [typeContactId, setTypeContactId] = useState()

    const defaultCivility = civility ?? 'default'


    useEffect(() => {
        setName(contact.name)
        setAddress(contact.address)
        setCity(contact.city)
        setContactFunction(contact.contactFunction)
        setCountryCode(contact.countryCode)
        setUpdateDate(moment().unix())
        setCivility(contact.civility)
        setPhone(contact.phone)
        setFax(contact.fax)
        setCellphone(contact.cellphone)
        setContactTitle(contact.title)
        setMail(contact.mail)
        setComment(contact.comment)
        setPhoneOffice(contact.phoneOffice)
        setTypeContactId(contact.typeContactId)
    }, [contact, isOpen])


    const create = useCallback(() => {
        const createdContact = {
            name,
            address,
            city,
            contactFunction,
            countryCode,
            updateDate,
            civility,
            phone,
            fax,
            cellphone,
            contactTitle,
            mail,
            comment,
            phoneOffice,
            typeContactId,
        }
        if (!name || name.trim() === '') {
            ToastrAction.error('Veuillez saisir un nom au contact')
        } else if (typeContactId % 1 !== 0 || typeContactId<0) {
            ToastrAction.error('l\'ID du type de contact doit etre un entier positif')
        } else {
            dispatch(ContactAction.createContact(createdContact))
                .then(() => {
                    dispatch(ContactAction.fetchContacts())
                })
                .finally(closePopin)
        }
    }, [
        name,
        address,
        city,
        contactFunction,
        countryCode,
        updateDate,
        civility,
        phone,
        fax,
        cellphone,
        contactTitle,
        mail,
        comment,
        phoneOffice,
        typeContactId,
        closePopin,
        dispatch,
    ])

    const update = useCallback(() => {
        const updatedContact = {
            name,
            address,
            city,
            contactFunction,
            countryCode,
            updateDate,
            civility,
            phone,
            fax,
            cellphone,
            contactTitle,
            mail,
            comment,
            phoneOffice,
            typeContactId,
        }
        if (name.trim() === '') {
            ToastrAction.error('Erreur de mise à jour : un nom ne peut pas être vide')
        } else if (typeContactId % 1 !== 0 || typeContactId<0) {
            ToastrAction.error('Erreur de mise à jour : ID du type de contact doit etre un entier positif')
        } else {
            dispatch(ContactAction.updateContact(contact.id, updatedContact))
                .then(() => {
                    dispatch(ContactAction.fetchContacts())
                })
                .finally(closePopin)
        }
    }, [
        name,
        address,
        city,
        contactFunction,
        countryCode, // pas affiché dans le tableau
        updateDate,
        civility,
        phone,
        fax, // pas affiché dans le tableau
        cellphone,
        contactTitle, // pas affiché dans le tableau
        mail,
        comment,
        phoneOffice, // pas affiché dans le tableau
        typeContactId,
        contact.id,
        closePopin,
        dispatch,
    ])

    return (
        <DialogMUI open={isOpen} onClose={closePopin} fullWidth maxWidth='md'>
            <DialogTitleMUI>
                {title}
                <IconButton
                    aria-label={'Fermer'}
                    onClick={closePopin}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                    <Grid item>
                        <StyledFieldSet>
                            <legend>{'Informations Personnelles'}</legend>
                            <Grid container style={{ gap: '10px' }} direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                                <Grid item spacing={4}>
                                    <InputLabel>{'Civilité'}</InputLabel>
                                    <Select
                                        label={'Civilité'}
                                        onChange={(e) => setCivility(e.target.value)}
                                        autoWidth
                                        value={defaultCivility}
                                        style={{
                                            padding: 0,
                                            height: '4vh',
                                            width: '12vw',
                                            backgroundColor: 'white',
                                            borderRadius: 5,
                                            border: '1px solid lightgrey',
                                        }}
                                    >
                                        <MenuItem disabled value='default'>{'Ne pas préciser'}</MenuItem>
                                        <MenuItem value='Mr'>{'Homme'}</MenuItem>
                                        <MenuItem value='Mme'>{'Femme'}</MenuItem>
                                    </Select>

                                </Grid>

                                <Grid item spacing={4}>
                                    <Input
                                        value={name}
                                        onChange={setName}
                                        placeholder={'Nom'}
                                    />
                                </Grid>


                                <Grid item spacing={4}>
                                    <Input
                                        value={address}
                                        onChange={setAddress}
                                        placeholder={'Adresse'}
                                    />
                                </Grid>

                                <Grid item spacing={4}>
                                    <Input
                                        value={city}
                                        onChange={setCity}
                                        placeholder={'Ville'}
                                    />
                                </Grid>

                                <Grid item spacing={4}>
                                    <Input
                                        value={countryCode}
                                        onChange={setCountryCode}
                                        placeholder={'Code pays'}
                                    />
                                </Grid>

                                <Grid item spacing={4}>
                                    <Input
                                        value={contactFunction}
                                        onChange={setContactFunction}
                                        placeholder={'Fonction'}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>


                    <Grid item>
                        <StyledFieldSet>
                            <legend>{'Contact'}</legend>
                            <Grid container style={{ gap: '15px', padding: '10px' }} direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Input
                                        value={phone}
                                        onChange={setPhone}
                                        placeholder={'Téléphone'}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={fax}
                                        onChange={setFax}
                                        placeholder={'Fax'}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={cellphone}
                                        onChange={setCellphone}
                                        placeholder={'Portable'}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={phoneOffice}
                                        onChange={setPhoneOffice}
                                        placeholder={'Téléphone Bureau'}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={mail}
                                        onChange={setMail}
                                        placeholder={'Email'}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    <Grid item>
                        <StyledFieldSet>
                            <legend>{'Informations complémentaires'}</legend>
                            <Grid container direction='row' justifyContent='space-around' alignItems='center' spacing={1}>
                                <Grid item spacing={4}>
                                    <Input
                                        value={contactTitle}
                                        onChange={setContactTitle}
                                        placeholder={'Titre'}
                                    />
                                </Grid>

                                <Grid item spacing={4}>
                                    <Textarea
                                        value={comment}
                                        onChange={setComment}
                                        placeholder={'Commentaire'}
                                    />
                                </Grid>

                                <Grid item spacing={4}>
                                    <TextField
                                        value={typeContactId}
                                        onChange={(e) => setTypeContactId(e.target.value)}
                                        type={'number'}
                                        placeholder={'ID du type de contact'}
                                        inputProps={{
                                            style: {
                                                padding: 5,
                                                width: '200px',
                                            },
                                            min: 0,
                                        }}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Tooltip title={'Sauvegarder'}>
                    <Button
                        variant='contained'
                        component='label'
                        autoFocus
                        onClick={contact.id ? update : create}
                    >
                        {contact.id ? 'Modifier' : 'Créer'}
                    </Button>
                </Tooltip>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ContactPopin.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    contact: PropTypes.shape({}),
}

export default ContactPopin