import { createSlice } from '@reduxjs/toolkit'
import DtoLicence from 'administration/user/dto/DtoLicence'

export const initialState = {
    licences: [],
}

const store = createSlice({
    name: 'licence',
    initialState,
    reducers: {
        receiveLicences: (state, action) => {
            state.licences = action.payload.map(p => new DtoLicence(p))
        },
    },
})

export const LicenceActionConstant = store.actions
export default store.reducer
