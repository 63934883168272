import { createTheme } from '@mui/material/styles'

const mainBlue = '#3f51b5'
const mainOrange = '#ee5b2e'
const secondaryBlue = '#232052'
const mainRed = '#f32525'
const badWarning = '#f6b427'
const mainBlack = '#161832'
const greyBlue = '#4f88b5'

const mainTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: () => ({
                body: {
                    margin: 0,
                    padding: 0,
                    lineHeight: 'normal',
                },
            }),
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '&:hover': {
                        color: 'white',
                    },
                },
                '&.Mui-active': {
                    color: 'white',
                },
                icon: {
                    color: 'white',
                    '& path': {
                        fill: 'white',
                    },
                },
            },
        },
    },
    palette: {
        primary: {
            main: secondaryBlue,
            grey: greyBlue,
        },
        secondary: {
            main: mainBlue,
        },
        action: {
            disabledBackground: 'lightgrey',
            disabled: 'white',
        },
        light: {
            main: 'white',
        },
    },
    typography: {
        fontFamily: ['"IBM Plex Sans"', 'sans-serif'].join(','),
        button: {
            fontSize: '1rem',
            textTransform: 'none',
        },
    },
})

export { mainBlue, mainOrange, secondaryBlue, mainRed, badWarning, mainBlack, mainTheme }