import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { ContactActionConstant } from 'reducers/ContactsReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from '../utils/ActionUtils'

const ContactAction = {
    fetchContacts: () => (dispatch) =>
        genericPromise(ApplicationConf.contacts.contacts(), 'GET')
            .then((result) => {
                dispatch(ContactActionConstant.receiveContacts(result))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contactListing} : ${err}`))
                ToastrAction.error(`${i18n.fetchError} ${i18n.contactListing}`)
            }),

    deleteContact: (id) => (dispatch) =>
        genericPromise(ApplicationConf.contacts.contact(id), 'DELETE')
            .then(() => {
                ToastrAction.success(i18n.elementDeleteSuccess)
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.deleteError} ${i18n.contactListing} : ${err}`))
                ToastrAction.error(`${i18n.deleteError} ${i18n.contactListing}`)
            }),

    updateContact: (id, updateContact) => (dispatch) =>
        genericPromise(ApplicationConf.contacts.contact(id), 'PUT', updateContact)
            .then(() => {
                ToastrAction.success(i18n.contactSuccessUpdated)
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.updateError} ${i18n.contactListing} : ${err}`))
                ToastrAction.error(`${i18n.updateError} ${i18n.contactListing}`)
            }),

    createContact: (createContact) => (dispatch) =>
        genericPromise(ApplicationConf.contacts.contacts(), 'POST', createContact)
            .then(() => {
                ToastrAction.success(i18n.elementAddSuccess)
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.addError} ${i18n.contactListing} : ${err}`))
                ToastrAction.error(`${i18n.addError} ${i18n.contactListing}`)
            }),
}

export default ContactAction
