import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { LicenceActionConstant } from 'reducers/LicenceReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from '../utils/ActionUtils'

const LicenceAction = {
    fetchLicences: () => (dispatch) =>
        genericPromise(ApplicationConf.licences.licences(), 'GET')
            .then((result) => {
                dispatch(LicenceActionConstant.receiveLicences(result))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.licenseListing} : ${err}`))
                ToastrAction.error(`${i18n.fetchError} ${i18n.licenseListing}`)
            }),

    deleteLicence: (id) => (dispatch) =>
        genericPromise(ApplicationConf.licences.licence(id), 'DELETE')
            .then(() => {
                ToastrAction.success(i18n.elementDeleteSuccess)
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.deleteError} ${i18n.licenseListing} : ${err}`))
                ToastrAction.error(`${i18n.deleteError} ${i18n.licenseListing}`)
            }),

    updateLicence: (id, updateLicense) => (dispatch) =>
        genericPromise(ApplicationConf.licences.licence(id), 'PUT', updateLicense)
            .then(() => {
                ToastrAction.success(i18n.licenseSuccessUpdated)
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.updateError} ${i18n.licenseListing} : ${err}`))
                ToastrAction.error(`${i18n.updateError} ${i18n.licenseListing}`)
            }),

    createLicence: (createLicence) => (dispatch) =>
        genericPromise(ApplicationConf.licences.licences(), 'POST', createLicence)
            .then(() => {
                ToastrAction.success(i18n.elementAddSuccess)
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.addError} ${i18n.licenseListing} : ${err}`))
                ToastrAction.error(`${i18n.addError} ${i18n.licenseListing}`)
            }),
}

export default LicenceAction
