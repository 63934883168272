import Card from 'components/card/Card'
import React, { useState, useEffect } from 'react'
import { Grid, Icon } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import '../../css/table.css'
import CustomerPopin from 'components/popin/CustomerPopin'
import useTitle from '../../../src/utils/customHook/useTitle'
import App from 'pages/online/components/App'
import CustomerAction from 'licence/CustomerAction'
import VirtualizedTable from '../../components/datatable/virtualizedTable/VirtualizedTable'
import moment from 'moment'
import { isUndefined } from 'lodash'
import PopinToConfirm from '../../components/popin/ValidationPopin'

const ListCustomerPanel = () => {
    const dispatch = useDispatch()

    const [isOpen, setOpen] = useState(false)

    const [isOpenValidationPopin, setOpenValidationPopin] = useState(false)

    const [idCustomerToDelete, setIdCustomerToDelete] = useState()

    const [customer, setCustomer] = useState({})

    useTitle(() => [{
        title: 'Liste des clients',
        href: 'ListCustomer',
    }], [])

    useEffect(() => {
        dispatch(CustomerAction.fetchCustomers())
    }, [dispatch])

    const { customers } = useSelector(
        (store) => ({
            customers: store.CustomerReducer.customers,
        }),
        shallowEqual
    )

    const deleteCustomer = () => {
        if (!isUndefined(idCustomerToDelete)) {
            dispatch(CustomerAction.deleteCustomer(idCustomerToDelete))
                .then(() => dispatch(CustomerAction.fetchCustomers()))
        }
    }

    const openPopinToConfirmDeletion = (elementToDelete) => {
        setIdCustomerToDelete(elementToDelete.id)
        setOpenValidationPopin(true)
    }

    const updateCustomer = (customerRow) => {
        setCustomer(customerRow)
        dispatch(CustomerAction.getCustomerLicence(customerRow.id))
        setOpen(true)
    }

    const { customersLicences } = useSelector(
        (store) => ({
            customersLicences: store.CustomerLicenceReducer.customersLicences,
        }),
        shallowEqual
    )

    const handleClick = () => {
        setCustomer({})
        setOpen(true)
    }

    const data = customers.map((el) => {
        return {
            delete: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer', color: 'red' }} title={'Supprimer'} onClick={() => {
                openPopinToConfirmDeletion(el)
            }}
            >delete</Icon> },
            edit: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer' }} title={'Modifier'} onClick={() => {
                updateCustomer(el)
            }}
            >edit</Icon> },
            name: { value: el.name },
            startDate: { value: moment(el.startDate).format('DD/MM/YYYY') },
            endDate: { value: moment(el.endDate).format('DD/MM/YYYY') },
            city: { value: el.city },
            address: { value: el.address },
            postalCode: { value: el.postalCode },
            type: { value: el.type },
            nameType: { value: el.nameType },
            logo: { value: el.logo },
            comment: { value: el.comment },
            progression: { value: el.progression },
            projectManager: { value: el.projectManager },
            projectManagerId: { value: el.projectManagerId },
        }
    })
    return (
        <Grid>
            <App handleClick={handleClick} data={customers}/>
            {customers.length && (
                <>
                    <Card maxWidth={window.innerWidth*0.9} cardStyle={{ marginTop: window.innerHeight*0.1 }}>
                        <VirtualizedTable
                            style={{ marginTop: '10px' }}
                            rowHeight={30}
                            columnWidth={150}
                            data={data}
                            headers={[
                                'delete',
                                'edit',
                                'name',
                                'startDate',
                                'endDate',
                                'city',
                                'address',
                                'postalCode',
                                'type',
                                'nameType',
                                'logo',
                                'comment',
                                'progression',
                                'projectManager',
                                'projectManagerId',
                            ]}
                        />
                    </Card>

                    <CustomerPopin
                        isOpen={isOpen}
                        title={customer.id && 'Modifier le client' || 'Ajouter un client'}
                        closePopin={() => setOpen(false)}
                        customer={customer}
                        customersLicences={customersLicences}
                    />

                    <PopinToConfirm
                        onValidate={deleteCustomer}
                        isOpen={isOpenValidationPopin}
                        closePopin={() => setOpenValidationPopin(false)}
                        customersLicences={customersLicences}
                        title={'Confirmer la suppression'}
                        content={'Êtes-vous sûr de vouloir supprimer ce client ?'}
                    />
                </>
            )}
        </Grid>
    )
}

ListCustomerPanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    closePopin: PropTypes.func,
}

export default ListCustomerPanel