import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { ContactActionConstant } from '../reducers/ContactsReducer'
import ToastrAction from '../toastr/actions/ToastrAction'
import { genericPromise } from '../utils/ActionUtils'

const ContactTypeAction = {
    fetchContactsType: () => (dispatch) =>
        genericPromise(ApplicationConf.contactsType.contactsType(), 'GET')
            .then((result) => {
                dispatch(ContactActionConstant.receiveContactsType(result))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de la récupération des données : Liste des types de contacts : ${err}`))
                ToastrAction.error('Erreur lors de la récupération des données : Liste des types de contacts')
            }),

    deleteContactType: (id) => (dispatch) =>
        genericPromise(ApplicationConf.contactsType.contactType(id), 'DELETE')
            .then(() => {
                ToastrAction.success('L\'élément a été supprimé')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur de suppression : Liste des types de contacts : ${err}`))
                ToastrAction.error('Erreur de suppression : Liste des types de contacts')
            }),

    updateContactType: (id, updateContactType) => (dispatch) =>
        genericPromise(ApplicationConf.contactsType.contactType(id), 'PUT', updateContactType)
            .then(() => {
                ToastrAction.success('L\'élément a été modifié')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur de mise à jour : Liste des types de contacts : ${err}`))
                ToastrAction.error('Erreur de mise à jour : Liste des types de contacts')
            }),

    createContactType: (createContactType) => (dispatch) =>
        genericPromise(ApplicationConf.contactsType.contactsType(), 'POST', createContactType)
            .then(() => {
                ToastrAction.success('L\'élément a été ajouté')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de l'ajout : Liste des types de contacts : ${err}`))
                ToastrAction.error('Erreur lors de l\'ajout : Liste des types de contacts')
            }),
}

export default ContactTypeAction