/* eslint-disable quotes */
import { push } from 'connected-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from 'log/actions/LogAction'
import WaitAction from 'wait/WaitAction'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { removeNullKeys } from './StoreUtils'
import AppStore from 'store/AppStore'
import { CREDENTIALS, SIEAU_TOKEN, TOKEN_MOBILE_LOGIN } from 'pages/offline/constants/HomeConstants'
import HomeAction from 'pages/offline/actions/HomeAction'

const codes = {
    200: (res) => res,
    201: (res) => res,
    403: () => {
        AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        AppStore.dispatch(push('/login'))
        localStorage.removeItem(SIEAU_TOKEN)
        AppStore.dispatch(HomeAction.disconnected('Une autre session a été ouverte avec votre compte'))
        throw new Error('Not Authorized')
    },
    404: () => {
        throw new Error('404 Not Found')
    },
    409: () => {
        throw new Error('409 Conflict')
    },
    429: () => {
        AppStore.dispatch(ToastrAction.error('Le nombre de tentatives de connexions est dépassé, votre compte a été bloqué. Veuillez contacter l\'administrateur'))
        throw new Error('429 Too Many Requests')
    },
    500: () => {
        throw new Error('500 error')
    },
}

const checkError = (json, errorCodeManagement = {}) => {
    if (json.error && errorCodeManagement[json.error]) {
        errorCodeManagement[json.error]()
    } else if (json.error) {
        AppStore.dispatch(WaitAction.waitStop())
        throw new Error(json.error)
    }
    return json
}

const resetCredentials = () => {
    localStorage.removeItem(SIEAU_TOKEN)
    localStorage.removeItem(CREDENTIALS)
    AppStore.dispatch(push('/login'))
}

const getLoginPassword = () => {
    const credentials = localStorage.getItem(CREDENTIALS)
    return credentials ? atob(credentials).split(':') : null
}

const getJson = response => response.json()

const checkStatus = (obj, response) => {
    const code = Object.keys(obj).find((element) => `${response.status}` === `${element}`)
    if (!code) {
        throw new Error(`Unhandled Error during fetch${response.status}`)
    }
    return obj[code](response)
}

const checkAuth = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const catchError = (err, message = '') => {
    AppStore.dispatch(WaitAction.waitStop())
    AppStore.dispatch(LogAction.logError(`Erreur lors de la récupération des données : ${message} : ${err}`))
    AppStore.dispatch(ToastrAction.error(`Erreur lors de la récupération des données : ${message}`))
}

const checkAuthV2 = (response, overrideStatus = {}) => {
    return checkStatus({
        ...codes,
        ...overrideStatus,
    }, response)
}

const getAuthorization = () => ({
    // Authorization: `Bearer ${localStorage.getItem(SIEAU_TOKEN)}`,
    Module: 'WEB',
    token: 'b5188d9a171bc80ed5ee517d7c489be0ddb48ec2feb41da652a682c45e62cd9f',
})

const getAuthorizationLogin = () => ({
    Authorization: TOKEN_MOBILE_LOGIN,
    Module: 'WEB',
})

const removeToken = () => {
    localStorage.removeItem(SIEAU_TOKEN)
}

const getPayload = () => {
    const token = localStorage.getItem(SIEAU_TOKEN)
    if (token) {
        return atob(token.split('.')[1])
    }
    return ''
}

const genericPromise = (url, method='GET', body=null, overrideStatus = {}) => {
    return fetch(url, removeNullKeys({
        method,
        headers: getAuthorization(),
        body: body ? JSON.stringify(body) : null,
    }))
        .then(r => checkAuth(r, overrideStatus))
        .then(getJson)
        .then(checkError)
}

const genericFetch = (callName, promise = async() => {}, errorName = '', cb = () => {}) => createAsyncThunk(
    callName,
    async(_, { rejectWithValue, dispatch }) => {
        try {
            const response = await promise()
            cb()
            return response
        } catch (err) {
            dispatch(LogAction.logError(`Erreur lors de la récupération des données : ${errorName}: ${err}`))
            dispatch(ToastrAction.error('Erreur lors de la récupération des données'))
            return rejectWithValue(err)
        }
    }
)


export {
    checkAuth,
    checkStatus,
    checkError,
    getAuthorization,
    getPayload,
    catchError,
    getLoginPassword,
    resetCredentials,
    getJson,
    getAuthorizationLogin,
    removeToken,
    checkAuthV2,
    genericPromise,
    genericFetch,
}
