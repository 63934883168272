import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { Icon, Grid } from '@mui/material'
import Burger from './Burger'
// import HomeAction from 'pages/offline/actions/HomeAction'
// import { cmsPath } from 'conf/basepath'
import { connect, useSelector } from 'react-redux'
import { shallowEqual } from 'react-redux'
import { useDispatch } from 'react-redux'
// import BoundaryError from 'log/components/BoundaryError'
// import { Route, Switch } from 'react-router'
// import Home from '../home/Home'
import { mainBlack } from 'components/styled/Theme'
// import ExportFileModal from '../../../components/modal/ExportFileModal'

const App = ({ title, handleClick, data }) => {
    const {
        // cmsLogo,
    } = useSelector(store => ({
        cmsLogo: store.HomeReducer.cmsLogo,
    }), shallowEqual)


    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (window.location.href.split('#')[1] === '/') {
            dispatch(push('/home'))
        }
        // dispatch(HomeAction.fetchCMSLogo(39)).then(() => {
        // const cmsLogoUrl = cmsLogo.document ? cmsPath + cmsLogo.document[0].name : ''
        // setLogoUrl(cmsLogoUrl)
        // })
    })

    useEffect(() => {
        if (window.location.href.split('#')[1] === '/') {
            dispatch(push('/home'))
        }
    }, [window.location.href])

    const toggleBurger = () => {
        setOpen(!open)
    }

    const exportData = (userInfo) => {
        const fileData = JSON.stringify(userInfo).replaceAll('},{', '}\n{')
        const blob = new Blob([fileData], { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = 'data.txt'
        link.href = url
        link.click()
    }

    const redirect = (href) => {
        if (href[0] === '/') {
            dispatch(push(href))
        } else {
            dispatch(push(`/${href}`))
        }
    }


    return (
        <div>
            <Burger
                toggleBurger={toggleBurger}
                open={open}
            />
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                style={{
                    zIndex: '1000',
                    position: 'fixed',
                    top: 0,
                    height: 50,
                    backgroundColor: mainBlack,
                    color: 'white',
                    padding: '0 15px',
                    borderTop: 'solid 1px grey',
                }}
            >
                <Grid
                    item
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1vw',
                    }}
                >
                    <Grid item style={{ cursor: 'pointer' }}>
                        <Icon onClick={toggleBurger} style={{ fontSize: '2rem' }}>menu</Icon>
                        <Icon style={{ color: 'white', margin: '0 0 3px 0' }}>keyboard_arrow_right</Icon>
                    </Grid>
                    {
                        title.map(element => {
                            if (!element.title) {
                                return null
                            }
                            const breadcrumb = element.title.length > 100 ? `${element.title.substring(0, 100)}...` : element.title
                            return (
                                <a key={ element.title } style={{ cursor: 'pointer' }} className='breadcrumb clickable' onClick={ () => redirect(element.href) }>
                                    { breadcrumb }
                                </a>
                            )
                        })
                    }

                </Grid>


                <Grid item style={{ cursor: 'pointer' }}>
                    { data ? <Icon style={{ cursor: 'pointer', color: 'white', marginRight: '50px' }} title={'Exporter'} onClick={() => {
                        exportData(data)
                    }}
                    >file_download</Icon> : null}
                    { handleClick ? <Icon title={'Ajouter'} onClick={handleClick}>note_add</Icon> : null }
                </Grid>

            </Grid>
            {/* <BoundaryError>
                <Switch>
                    <Route exact path='/home' component={(props) => <Home {...props} />} />
                </Switch>
            </BoundaryError> */}
        </div >
    )
}

App.propTypes = {
    title: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
    })),
    handleClick: PropTypes.func,
    data: PropTypes.object,
}

const mapStateToProps = (store) => ({
    title: store.HomeReducer.title,
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
