import { createSlice } from '@reduxjs/toolkit'
import DtoCustomerType from 'administration/user/dto/DtoCustomerType'

export const initialState = {
    customersType: [],
}

const store = createSlice({
    name: 'customerType',
    initialState,
    reducers: {
        receiveCustomersType: (state, action) => {
            state.customersType = action.payload.map(p => new DtoCustomerType(p))
        },
    },
})

export const CustomerTypeActionConstant = store.actions
export default store.reducer
