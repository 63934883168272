export default class DtoCustomer {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.city = obj.city
        this.address = obj.address
        this.postalCode = obj.postalCode
        this.type = obj.type
        this.nameType = obj.nameType
        this.logo = obj.logo
        this.comment = obj.comment
        this.progression = obj.progression
        this.projectManagerId = obj.projectManagerId
        this.projectManager = obj.projectManager
    }
}