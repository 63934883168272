export const mainBlue = '#35609F'
export const secondaryBlue = '#53A1FF'
export const darkBlue = '#161832'
export const greyBlue = '#4f88b5'
export const lightBlue = '#9BC2E6'
export const lightGrey = 'rgb(208, 208, 208)'
export const mediumGrey = '#75838F'
export const disabledColor = '#949494'
export const selectedColor = '#e0e0e0'
export const mainWhite = '#fafafa'
