'use strict'
import DtoCMSEvent from 'pages/online/cms/dto/DtoCMSEvent'
import { TITLE, RECEIVE_DATE_VALID_CGU, RECEIVE_PARTENAIRE_CMS, RECEIVE_CGU_CMS, RECEIVE_CMS_LOGO, RECEIVE_APPLICATION_SETTINGS, SET_RELOAD, DISCONNECTED } from '../constants/HomeConstants'
import DtoApplicationSettings from '../dto/DtoApplicationSettings'

export const store = {
    message: '',
    title: [],
    dateValidCgu: '',
    cms: [],
    cmsCGU: [],
    cmsLogo: {},
    applicationSettings: [],
    reload: false,
}

export function HomeReducer(state = {}, action) {
    switch (action.type) {
        case TITLE:
            return {
                ...state,
                title: action.title,
            }
        case RECEIVE_DATE_VALID_CGU:
            return {
                ...state,
                dateValidCgu: action.date,
            }
        case RECEIVE_PARTENAIRE_CMS:
            return {
                ...state,
                cms: action.cms,
            }
        case RECEIVE_CGU_CMS:
            return {
                ...state,
                cmsCGU: action.cmsCGU.map((c) => new DtoCMSEvent(c)),
            }
        case RECEIVE_CMS_LOGO:
            return {
                ...state,
                cmsLogo: new DtoCMSEvent(action.cmsLogo),
            }
        case DISCONNECTED:
            return {
                ...state,
                message: action.message,
            }
        case SET_RELOAD:
            return {
                ...state,
                reload: action.reload,
            }
        case RECEIVE_APPLICATION_SETTINGS:
            return {
                ...state,
                applicationSettings: action.applicationSettings.map((s) => new DtoApplicationSettings(s)),
            }
        default:
            return state
    }
}
