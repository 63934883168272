import { createSlice } from '@reduxjs/toolkit'
import DtoProjectManager from 'administration/user/dto/DtoProjectManager'

export const initialState = {
    charges: [],
}

const store = createSlice({
    name: 'projectManager',
    initialState,
    reducers: {
        receiveCharges: (state, action) => {
            state.customers = action.payload.map(p => new DtoProjectManager(p))
        },
    },
})

export const ProjectManagerActionConstant = store.actions
export default store.reducer
