import { RECEIVE_CMS_ACTUALITIES } from '../constants/CmsConstants'
import DtoCMSEvent from '../dto/DtoCMSEvent'

export function CmsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CMS_ACTUALITIES:
            return { ...state, actualities: action.actualities.map((n) => new DtoCMSEvent(n)) }
        default:
            return state
    }
}

export const store = {
    actualities: [],
}
