import { createSlice } from '@reduxjs/toolkit'
import DtoCustomer from 'administration/user/dto/DtoCustomer'
import DtoCustomerLicence from '../administration/user/dto/DtoCustomerLicence'

export const initialState = {
    customers: [],
    customersLicences: [],
}

const store = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        receiveCustomers: (state, action) => {
            state.customers = action.payload.map(p => new DtoCustomer(p))
        },
        receiveCustomersLicences: (state, action) => {
            state.customersLicences = action.payload.licence.map(p => new DtoCustomerLicence(p))
        },
    },
})

export const CustomerActionConstant = store.actions
export default store.reducer
