/* eslint-disable no-console */
import fetch from 'isomorphic-fetch'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_STATISTICS,
} from '../constants/AccountConstants'
import ToastrAction from 'toastr/actions/ToastrAction'

const AccountAction = {
    receiveUser(accountUser) {
        return {
            type: RECEIVE_ACCOUNT,
            accountUser,
        }
    },

    fetchUserData(login) {
        return dispatch => Promise.all([
            AccountAction.promiseUser(login),
            AccountAction.promiseAccountStatistics(login),
        ]).then(jsonTab => {
            dispatch(AccountAction.receiveUser(jsonTab[0]))
            dispatch(AccountAction.receiveAccountStatistics(jsonTab[1]))
        })
    },

    promiseUser(login) {
        return fetch(ApplicationConf.user.get(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchUser(login, onLoginCallback = () => {}) {
        return (dispatch) => {
            return AccountAction.promiseUser(login)
                .then((json) => {
                    dispatch(AccountAction.receiveUser(json))
                    dispatch(AccountAction.fetchAccountStatistics(json.login))
                    onLoginCallback()
                })
        }
    },

    receiveAccountStatistics(accountStatistics) {
        return { type: RECEIVE_ACCOUNT_STATISTICS, accountStatistics }
    },

    promiseAccountStatistics(login) {
        return fetch(ApplicationConf.user.getStatistics(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchAccountStatistics(login) {
        return (dispatch) => {
            return AccountAction.promiseAccountStatistics(login)
                .then((json) => {
                    dispatch(AccountAction.receiveAccountStatistics(json))
                })
        }
    },

    updatePassword(login, password) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.updatePassword(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    if (json.status === 200) {
                        dispatch(ToastrAction.success('L\'élément a été modifié', true))
                    } else {
                        dispatch(ToastrAction.error('Erreur de mise à jour : ' + 'Mot de passe', true))
                    }
                })
                .catch((err) => {
                    console.error(`Erreur de mise à jour : Mot de passe : ${err}`)
                })
        }
    },
}

export default AccountAction
