import React, { useCallback, useEffect, useState } from 'react'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import {
    Button,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextareaAutosize,
    TextField,
    Tooltip,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import CustomerAction from 'licence/CustomerAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'
import { StyledFieldSet } from '../styled/StyledElement'
import Input from '../forms/Input'
import CustomerTypeAction from '../../licence/CustomerTypeAction'
import VirtualizedTable from '../datatable/virtualizedTable/VirtualizedTable'
import Card from 'components/card/Card'
import LicenceAction from '../../licence/LicenceAction'
import { makeStyles } from '@mui/styles'
import { isUndefined } from 'lodash'
import PopinToConfirm from './ValidationPopin'

const useStyles = makeStyles({
    textField: {
        width: '100px',
        marginRight: '5px',
        padding: '0',
        height: '30px',
    },
    textFieldMargin: {
        marginRight: '5px',
    },
    input: {
        width: '100%',
        height: '30px',
        padding: '5',
        borderRadius: '5px',
        borderWidth: '1px',
    },
})

const CustomerPopin = ({
    title = '',
    isOpen = false,
    customer = {},
    customersLicences = [],
    closePopin = () => {},
}) => {
    const dispatch = useDispatch()

    const classes = useStyles()

    const [name, setName] = useState()

    const [startDate, setStartDate] = useState()

    const [endDate, setEndDate] = useState()

    const [city, setCity] = useState()

    const [address, setAddress] = useState()

    const [postalCode, setPostalCode] = useState()

    const [type, setType] = useState()

    const [nameType, setNameType] = useState()

    const [logo, setLogo] = useState()

    const [comment, setComment] = useState()

    const [progression, setProgression] = useState()

    const [projectManagerId, setProjectManagerId] = useState()

    const [licenceId, setLicenceId] = useState()

    const [startDateLicence, setStartDateLicence] = useState()

    const [endDateLicence, setEndDateLicence] = useState()

    const [valueLicence, setValueLicence] = useState()

    const [isOpenValidationPopin, setOpenValidationPopin] = useState(false)

    const [idLicenceInCustomerToDelete, setIdLicenceInCustomerToDelete] = useState()

    useEffect(() => {
        setName(customer.name)
        setStartDate(customer.startDate)
        setEndDate(customer.endDate)
        setCity(customer.city)
        setAddress(customer.address)
        setPostalCode(customer.postalCode)
        setType(customer.type)
        setNameType(customer.nameType)
        setLogo(customer.logo)
        setComment(customer.comment)
        setProgression(customer.progression)
        setProjectManagerId(customer.projectManagerId)
        dispatch(CustomerTypeAction.fetchCustomersType())
        dispatch(LicenceAction.fetchLicences())
    }, [customer, isOpen, dispatch])

    const { customersTypes } = useSelector(
        (store) => ({
            customersTypes: store.CustomerTypeReducer.customersType,
        }),
        shallowEqual
    )

    const deleteLicenceLinkedToCustomer = () => {
        if (!isUndefined(idLicenceInCustomerToDelete)) {
            dispatch(CustomerAction.deleteCustomerLicence(customer.id, idLicenceInCustomerToDelete))
                .finally(() => dispatch(CustomerAction.getCustomerLicence(customer.id)))
        }
    }

    const openPopinToConfirmDeletion = (elementToDelete) => {
        setIdLicenceInCustomerToDelete(elementToDelete.id)
        setOpenValidationPopin(true)
    }

    const tabCustomerLicence = customersLicences.map((el) => {
        return {
            delete: { value: <Icon fontSize={'small'} style={{ cursor: 'pointer', color: 'red' }} title={'Supprimer'} onClick={() => {
                openPopinToConfirmDeletion(el)
            }}
            >delete</Icon> },
            name: { value: el.name },
            value: { value: el.value },
        }
    })

    const checkNewCustomer = () => {
        if (isUndefined(customer.id)) {
            return []
        }
        return tabCustomerLicence
    }

    const create = useCallback(() => {
        const createdCustomer = {
            name,
            startDate,
            endDate,
            city,
            address,
            postalCode,
            type,
            nameType,
            logo,
            comment,
            progression,
            projectManagerId,
        }
        if (!name || name.trim() === '') {
            ToastrAction.error('Veuillez saisir un nom au client')
        } else {
            dispatch(CustomerAction.createCustomer(createdCustomer))
                .then(() => {
                    dispatch(CustomerAction.fetchCustomers())
                })
                .finally(closePopin)
        }
    }, [
        name,
        startDate,
        endDate,
        city,
        address,
        postalCode,
        type,
        nameType,
        logo,
        comment,
        progression,
        projectManagerId,
        closePopin,
        dispatch,
    ])

    const update = useCallback(() => {
        const updatedCustomer = {
            name,
            startDate,
            endDate,
            city,
            address,
            postalCode,
            type,
            nameType,
            logo,
            comment,
            progression,
            projectManagerId,
        }
        if (name.trim() === '') {
            ToastrAction.error('Erreur de mise à jour : un nom ne peut pas être vide')
        } else {
            dispatch(CustomerAction.updateCustomer(customer.id, updatedCustomer))
                .then(() => {
                    dispatch(CustomerAction.fetchCustomers())
                })
                .finally(closePopin)
        }
    }, [
        name,
        startDate,
        endDate,
        city,
        address,
        postalCode,
        type,
        nameType,
        logo,
        comment,
        progression,
        projectManagerId,
        customer.id,
        closePopin,
        dispatch,
    ])

    const createLicence = useCallback(() => {
        if (!licenceId) {
            ToastrAction.error('Veuillez saisir un id à la licence')
        } else {
            dispatch(CustomerAction.createCustomerLicence(customer.id, licenceId, { startDate: startDateLicence, endDate: endDateLicence, value: valueLicence }))
                .finally(() => dispatch(CustomerAction.getCustomerLicence(customer.id)))
        }
    }, [customer.id, licenceId, startDateLicence, endDateLicence, valueLicence, dispatch])

    const handleChangeDropdownType = (event) => {
        setType(event.target.value)
    }

    return (
        <DialogMUI open={isOpen} onClose={closePopin} maxWidth='xl'>
            <DialogTitleMUI>
                {title}
                <IconButton
                    aria-label={'Fermer'}
                    onClick={closePopin}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container direction='row' justifyContent='start' alignItems='start' gap='5px'>
                    <Grid item style={{ width: '50%' }}>
                        <StyledFieldSet style={{ minHeight: '50px' }}>
                            <legend>{'Informations Personnelles'}</legend>
                            <Grid container style={{ gap: '10px' }} direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Input
                                        value={name}
                                        onChange={setName}
                                        placeholder={'Nom'}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <TextField
                                        type='date'
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        inputProps={{
                                            style: {
                                                padding: 5,
                                                width: '110px',
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item>
                                    <TextField
                                        type='date'
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        inputProps={{
                                            style: {
                                                padding: 5,
                                                width: '110px',
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>

                    <Grid item style={{ width: '49%' }}>
                        <StyledFieldSet style={{ minHeight: '50px' }}>
                            <legend>{'Adresse'}</legend>
                            <Grid container style={{ gap: '10px' }} direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Input
                                        value={city}
                                        placeholder={'Ville'}
                                        onChange={setCity}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={address}
                                        placeholder={'Adresse'}
                                        onChange={setAddress}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={postalCode}
                                        placeholder={'Code Postal'}
                                        onChange={(e) => setPostalCode(parseInt(e, 10))}
                                        className={classes.input}
                                    />
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>

                    <Grid item style={{ width: '100%' }}>
                        <StyledFieldSet style={{ minHeight: '50px' }}>
                            <legend>{'Informations Entreprises'}</legend>
                            <Grid container style={{ gap: '10px' }} direction='row' justifyContent='space-between' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Input
                                        value={logo}
                                        placeholder={'Logo'}
                                        onChange={setLogo}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <TextareaAutosize
                                        value={comment}
                                        placeholder={'Commentaire'}
                                        label={'Commentaire'}
                                        onChange={(e) => setComment(e.target.value)}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={progression}
                                        placeholder={'Avancement'}
                                        onChange={(e) => setProgression(parseInt(e, 10))}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <Input
                                        value={projectManagerId}
                                        placeholder={'ID du chargé de projet'}
                                        onChange={(e) => setProjectManagerId(parseInt(e, 10))}
                                        className={classes.input}
                                    />
                                </Grid>

                                <Grid item>
                                    <InputLabel sx={{ color: 'black' }}>Type de Client</InputLabel>
                                    <Select
                                        label='Type de client'
                                        defaultValue={type}
                                        sx={{
                                            height: 25,
                                        }}
                                        onChange={handleChangeDropdownType}
                                    >
                                        {customersTypes.map(typeCustomer => <MenuItem value={typeCustomer.id}>{typeCustomer.name}</MenuItem>)}
                                    </Select>
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </Grid>
                    {!isUndefined(customer.id) &&
                        <Grid item style={{ width: '50%' }}>
                            <StyledFieldSet style={{ minHeight: '50px' }}>
                                <legend>{'Licences'}</legend>
                                <Grid container style={{ gap: '10px' }} direction='column' justifyContent='space-between' alignItems='start' spacing={1}>
                                    <Grid item container direction='row' style={{ gap: '5px' }}>
                                        <TextField
                                            value={licenceId}
                                            onChange={(e) => setLicenceId(e.target.value)}
                                            type={'number'}
                                            placeholder={'ID Licence'}
                                            inputProps={{
                                                style: {
                                                    padding: 5,
                                                    width: '100px',
                                                },
                                                min: 0,
                                            }}
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                        />
                                        <TextField
                                            type='date'
                                            value={startDateLicence}
                                            onChange={(e) => setStartDateLicence(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    padding: 5,
                                                    width: '110px',
                                                },
                                            }}
                                        />
                                        <TextField
                                            type='date'
                                            value={endDateLicence}
                                            onChange={(e) => setEndDateLicence(e.target.value)}
                                            inputProps={{
                                                style: {
                                                    padding: 5,
                                                    width: '110px',
                                                },
                                            }}
                                        />
                                        <Input
                                            value={valueLicence}
                                            placeholder={'Valeur Licence'}
                                            onChange={(e) => setValueLicence(parseFloat(e))}
                                            className={classes.input}
                                        />
                                        <Button style={{ padding: '0' }} onClick={createLicence}><Icon>add</Icon></Button>
                                    </Grid>
                                    <Grid item>
                                        <Card cardStyle={{ width: '500px' }} height={300}>
                                            <VirtualizedTable headers={['delete', 'name', 'value']} columnWidth={150} rowHeight={20} data={checkNewCustomer()}/>
                                        </Card>
                                        <PopinToConfirm
                                            onValidate={deleteLicenceLinkedToCustomer}
                                            isOpen={isOpenValidationPopin}
                                            closePopin={() => setOpenValidationPopin(false)}
                                            title={'Confirmer la suppression'}
                                            content={'Êtes-vous sûr de vouloir supprimer cette licence ?'}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </Grid>
                    }
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Tooltip title={'Sauvegarder'}>
                    <Button
                        variant='contained'
                        component='label'
                        autoFocus
                        onClick={customer.id ? update : create}
                    >
                        {customer.id ? 'Modifier' : 'Créer'}
                    </Button>
                </Tooltip>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

CustomerPopin.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    customer: PropTypes.shape({}),
    customersLicences: PropTypes.arrayOf(PropTypes.shape({})),
}

export default CustomerPopin