import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import HomeAction from '../../offline/actions/HomeAction'
import { Grid, Divider, Drawer, Icon, List, ListItemIcon, ListItemText, ListItemButton, Collapse } from '@mui/material'
import packageJson from '../../../../package.json'
// import ContactAction from '../contact/actions/ContactAction'
import { mainBlack } from 'components/styled/Theme'

const Burger = ({
    toggleBurger = () => {},
    open = false,
}) => {
    useSelector(store => ({
        settings: store.ContactReducer.settings,
    }), shallowEqual)

    const [submenu, setSubmenu] = useState()

    const dispatch = useDispatch()

    const onClickButton = (path) => {
        toggleBurger()
        dispatch(push(`/${path}`))
        window.scrollTo(0, 0)
    }

    const toggleSubmenu = () => {
        setSubmenu(!submenu)
    }

    return (
        <div>
            <Drawer
                anchor='left'
                open={open}
                onClose={() => {
                    toggleBurger()
                    setSubmenu(false)
                }}
                style={{
                    zIndex: '2000',
                    width: '20vw',
                }}
            >
                <List style={{ marginTop: 60 }} disablePadding>
                    <ListItemButton onClick={() => onClickButton('home')}>
                        <ListItemIcon>
                            <Icon>home</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Accueil'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => onClickButton('ListLicence')}>
                        <ListItemText primary={'Liste des licences'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => onClickButton('ListProduct')}>
                        <ListItemText primary={'Liste des produits'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => onClickButton('ListContact')}>
                        <ListItemText primary={'Liste des contacts'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => onClickButton('ListContactType')}>
                        <ListItemText primary={'Liste des type de contacts'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => onClickButton('ListCustomer')}>
                        <ListItemText primary={'Liste des clients'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => onClickButton('ListCustomerType')}>
                        <ListItemText primary={'Liste des types de clients'} />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton
                        onClick={() => {
                            dispatch(HomeAction.logout())
                            toggleSubmenu()
                        }}
                        selected={!submenu}
                    >
                        <ListItemIcon>
                            <Icon>power_settings_new</Icon>
                        </ListItemIcon>
                        <ListItemText primary={'Déconnexion'} />
                    </ListItemButton>
                    <Divider />
                </List>
                <Grid
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{
                        position: 'fixed',
                        top: 0,
                        height: 50,
                        padding: '0 15px',
                        backgroundColor: mainBlack,
                        color: 'white',
                    }}
                >
                    <Grid item>
                        <Icon onClick={toggleBurger} style={{ fontSize: '2rem', cursor: 'pointer' }}>
                            menu
                        </Icon>
                    </Grid>
                    <Grid item>
                        <span>v{packageJson.version}</span>
                    </Grid>
                </Grid>
            </Drawer>
        </div>
    )
}

Burger.propTypes = {
    toggleBurger: PropTypes.func,
    open: PropTypes.bool,
}

export default Burger
