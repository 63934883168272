import React from 'react'
import useTitle from '../../utils/customHook/useTitle'
import App from '../online/components/App'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'

const ListProductPanel = () => {
    // const dispatch = useDispatch()

    // const [isOpen, setOpen] = useState(false)
    // const [product, setProduct] = useState({})

    useTitle(() => [{
        title: 'Liste des produits',
        href: 'ListProduct',
    }], [])

    const handleClick = () => {
        // setProduct({})
        // setOpen(true)
    }

    return (
        <Grid>
            <App handleClick={handleClick}/>
        </Grid>
    )
}

ListProductPanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    close: PropTypes.func,
    titlePage: PropTypes.string,
}

export default ListProductPanel