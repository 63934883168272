module.exports = {
    SIEAU_TOKEN: 'sieau-token',
    SIEAU_LOGIN: 'SIEAU_LOGIN',
    DISCONNECTED: 'DISCONNECTED',
    TITLE: 'TITLE',
    CREDENTIALS: 'CREDENTIALS',
    ACTIONS: 'ACTIONS',
    RECEIVE_DATE_VALID_CGU: 'RECEIVE_DATE_VALID_CGU',
    RECEIVE_PARTENAIRE_CMS: 'RECEIVE_PARTENAIRE_CMS',
    RECEIVE_CGU_CMS: 'RECEIVE_CGU_CMS',
    RECEIVE_CMS_LOGO: 'RECEIVE_CMS_LOGO',
    ACTION: 'ACTION',
    MENU: 'MENU',
    RECEIVE_APPLICATION_SETTINGS: 'RECEIVE_APPLICATION_SETTINGS',
    RECEIVE_CMS_HOME: 'RECEIVE_CMS_HOME',
    SET_RELOAD: 'SET_RELOAD',
    TOKEN_MOBILE_LOGIN:
        // eslint-disable-next-line max-len
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MDE1ODcyMzc3OTgsImxvZ2luIjoibWVkZWF1IiwiY29ubmVjdGlvbiI6IjIwMjAtMTAtMDEgMTE6MjA6MzciLCJsaWNlbnNlIjpbIlNJRyIsIlNJQURNIiwiU0lQIiwiU0lIIiwiU0lNQVQiLCJTSVEiLCJTSUVTIl19.MtQxgmnsWfRz1iXLj-0TR7f3SKz3YfQO2K-AUgHA8jU',
}