import PropTypes from 'prop-types'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../styled/Dialog'
import { Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React from 'react'

const PopinToConfirm = ({
    onValidate,
    isOpen = false,
    closePopin = () => {},
    title = '',
    content = '',
}) => {
    return (
        <DialogMUI open={isOpen} onClose={closePopin} maxWidth='sm' PaperProps={{ sx: { maxHeight: '50vh' } }}>
            <DialogTitleMUI>
                {title}
                <IconButton
                    aria-label={'Fermer'}
                    onClick={closePopin}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitleMUI>

            <DialogContentMUI style={{ textAlign: 'center' }}>
                <p>{content}</p>
            </DialogContentMUI>

            <DialogActionsMUI>
                <Button
                    onClick={closePopin}
                >
                    NON
                </Button>
                <Button
                    onClick={() => {
                        onValidate()
                        closePopin()
                    }}
                >
                    OUI
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PopinToConfirm.propTypes = {
    onValidate: PropTypes.func,
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
}

export default PopinToConfirm