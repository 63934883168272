/* eslint-disable react-hooks/exhaustive-deps */
import HomeAction from '../../../src/pages/offline/actions/HomeAction'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

const useTitle = (callback, dependencies = []) => {
    const dispatch = useDispatch()

    const titleMemoize = useMemo(callback, dependencies)

    useEffect(() => dispatch(HomeAction.setTitle(titleMemoize)), dependencies)
}

export default useTitle