import {
    RECEIVE_SETTINGS,
} from '../constants/ContactConstants'
import SettingDto from '../dto/SettingDto'

export const store = {
    settings: [],
}
export function ContactReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_SETTINGS:
            return {
                ...state,
                settings: action.settings.map(setting => new SettingDto(setting)),
            }
        default:
            return state
    }
}
