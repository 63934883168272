import React, { useCallback, useEffect, useState } from 'react'
import { DialogMUI, DialogTitleMUI, DialogContentMUI, DialogActionsMUI } from 'components/styled/Dialog'
import { Button, Grid, IconButton, TextareaAutosize, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import DtoLicence from 'administration/user/dto/DtoLicence'
import LicenceAction from 'licence/LicenceAction'
import { useDispatch } from 'react-redux'
import { ColorBox } from 'material-ui-color'
import ToastrAction from 'toastr/actions/ToastrAction'
import { StyledFieldSet } from '../styled/StyledElement'
import Input from '../forms/Input'


const LicencePopin = ({
    title = '',
    isOpen = false,
    license = {},
    closePopin = () => {},
}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState()

    const [description, setDescription] = useState()

    const [color, setColor] = useState()

    useEffect(() => {
        setName(license.name)
        setDescription(license.description)
        setColor(license.color)
    }, [license, isOpen])

    const create = useCallback(() => {
        const createdLicense = { name, description, color }
        if (!name || name.trim() === '') {
            ToastrAction.error('Veuillez saisir un nom à la licence')
        } else {
            dispatch(LicenceAction.createLicence(new DtoLicence(createdLicense)))
                .then(() => {
                    dispatch(LicenceAction.fetchLicences())
                })
                .finally(closePopin)
        }
    }, [name, description, color, closePopin, dispatch])

    const update = useCallback(() => {
        const UpdatedLicence = { name, description, color }
        if (name.trim() === '') {
            ToastrAction.error('Erreur de mise à jour : un nom ne peut pas être vide')
        } else {
            dispatch(LicenceAction.updateLicence(license.id, new DtoLicence(UpdatedLicence)))
                .then(() => {
                    dispatch(LicenceAction.fetchLicences())
                })
                .finally(closePopin)
        }
    }, [name, description, color, license.id, closePopin, dispatch])

    return (
        <DialogMUI open={isOpen} onClose={closePopin} maxWidth='xs'>
            <DialogTitleMUI>
                {title}
                <IconButton
                    aria-label={'Fermer'}
                    onClick={closePopin}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
                    <Grid item marginTop={2}>
                        <StyledFieldSet>
                            <legend>{'Information'}</legend>

                            <Input
                                value={name}
                                placeholder={'Nom'}
                                onChange={setName}
                                style={{
                                    marginBottom: 10,
                                    width: '100%',
                                }}
                            />

                            <TextareaAutosize
                                value={description}
                                placeholder={'Description'}
                                onChange={(e) => setDescription(e.target.value)}
                                minRows={3}
                                maxRows={10}
                                style={{
                                    minWidth: 300,
                                    maxWidth: 400,
                                    maxHeight: 300,
                                    fontFamily: 'Sans-Serif',
                                    fontSize: 15,
                                }}
                            />
                        </StyledFieldSet>

                    </Grid>
                    <Grid item marginTop={5}>
                        <ColorBox
                            disableAlpha
                            value={color}
                            onChange={(e) => setColor(`#${e.hex}`)}
                            style={{ backgroundColor: '#F0F0F0' }}
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Tooltip title={'Sauvegarder'}>
                    <Button
                        variant='contained'
                        component='label'
                        autoFocus
                        onClick={license.id ? update : create}
                    >
                        {license.id ? 'Modifier' : 'Créer'}
                    </Button>
                </Tooltip>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

LicencePopin.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    license: PropTypes.shape({}),
}

export default LicencePopin