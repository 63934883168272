import React, { useState, useEffect } from 'react'
import { Grid, Icon } from '@mui/material'
import Card from '../../components/card/Card'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import LicenceAction from 'licence/LicenceAction'
import PropTypes from 'prop-types'
import '../../css/table.css'
import LicencePopin from 'components/popin/LicencePopin'
import useTitle from '../../utils/customHook/useTitle'
import App from 'pages/online/components/App'
import VirtualizedTable from '../../components/datatable/virtualizedTable/VirtualizedTable'
import PopinToConfirm from '../../components/popin/ValidationPopin'
import { isUndefined } from 'lodash'

const ListLicencePanel = () => {
    const dispatch = useDispatch()

    const [isOpen, setOpen] = useState(false)

    const [isOpenValidationPopin, setOpenValidationPopin] = useState(false)

    const [idLicenceToDelete, setIdLicenceToDelete] = useState()

    useTitle(() => [{
        title: 'Liste des licences',
        href: 'ListLicence',
    }], [])

    useEffect(() => {
        dispatch(LicenceAction.fetchLicences())
    }, [dispatch])

    const { licences } = useSelector(
        (store) => ({
            licences: store.LicenceReducer.licences,
        }),
        shallowEqual
    )

    const [license, setLicense] = useState({})

    const deleteLicense = () => {
        if (!isUndefined(idLicenceToDelete)) {
            dispatch(LicenceAction.deleteLicence(idLicenceToDelete))
                .then(() => dispatch(LicenceAction.fetchLicences()))
        }
    }

    const openPopinToConfirmDeletion = (elementToDelete) => {
        setIdLicenceToDelete(elementToDelete.id)
        setOpenValidationPopin(true)
    }

    const updateLicense = (licenseRow) => {
        setLicense(licenseRow)
        setOpen(true)
    }

    const handleClick = () => {
        setLicense({})
        setOpen(true)
    }

    const data = licences.map((el) => {
        return {
            delete: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer', color: 'red' }} title={'Supprimer'} onClick={() => {
                openPopinToConfirmDeletion(el)
            }}
            >delete</Icon> },
            edit: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer' }} title={'Modifier'} onClick={() => {
                updateLicense(el)
            }}
            >edit</Icon> },
            id: { value: el.id },
            name: { value: el.name },
            description: { value: el.description },
            color: { value: <div style={{ background: el.color, width: '100%', height: '100%' }}/> },
        }
    })


    return (
        <Grid>
            <App handleClick={handleClick} data={licences}/>
            {licences.length && (
                <>
                    <Card maxWidth={window.innerWidth*0.7} cardStyle={{ marginTop: window.innerHeight*0.1 }}>
                        <VirtualizedTable
                            style={{ marginTop: '10px' }}
                            rowHeight={30}
                            columnWidth={200}
                            data={data}
                            headers={['delete', 'edit', 'id', 'name', 'description']}
                        />
                    </Card>

                    <LicencePopin
                        isOpen={isOpen}
                        title={license.id && 'Editer la licence' || 'Ajouter une licence'}
                        closePopin={() => setOpen(false)}
                        license={license}
                    />

                    <PopinToConfirm
                        onValidate={deleteLicense}
                        isOpen={isOpenValidationPopin}
                        closePopin={() => setOpenValidationPopin(false)}
                        title={'Confirmer la suppression'}
                        content={'Êtes-vous sûr de vouloir supprimer cette licence ?'}
                    />
                </>
            )}
        </Grid>
    )
}

ListLicencePanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    close: PropTypes.func,
    titlePage: PropTypes.string,
}


export default ListLicencePanel