import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Card, Grid, Modal, Paper } from '@mui/material'
import DtoCMSEvent from 'pages/online/cms/dto/DtoCMSEvent'
import { PrimaryButton } from 'components/styled/Buttons'
import { sortById } from '../../../utils/ObjectUtils'

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
    },
    title: {
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '8px',
        boxShadow: 'none',
    },
    text: {
        fontSize: '16px',
        boxShadow: 'none',
        textAlign: 'justify',
        width: '100%',
        border: 'none',
        font: 'unset',
        resize: 'none',
        backgroundColor: 'white',
        color: 'black',
        opacity: 'unset',
    },
}))

const ModalCGU = ({
    open = false,
    cgu = [],
    onRefuse = () => {},
    onValidate = () => {},
}) => {
    const classes = useStyles()

    const showCMS = () => {
        const cguSorted = cgu.sort(sortById)
        return cguSorted.map((cms) => {
            return (
                <Grid key={cms.id} item xs={12}>
                    <Paper className={classes.title}>{cms.title}</Paper>
                    <div className={classes.text} style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: cms.comment || '' }} />
                </Grid>
            )
        })
    }

    return (
        <Modal
            open={open}
            className={classes.paper}
            onClose={onRefuse}
            style={{
                bottom: 'inherit',
                zIndex: '1000',
            }}
        >
            <Card style={{ padding: '14px', height: '100%', overflowY: 'auto' }}>
                {showCMS()}
                <PrimaryButton margintop variant='contained' onClick={onValidate}>
                    {'Valider'}
                </PrimaryButton>
                <PrimaryButton reverse variant='contained' onClick={onRefuse} margintop>
                    {'Annuler'}
                </PrimaryButton>
            </Card>
        </Modal>
    )
}

ModalCGU.propTypes = {
    open: PropTypes.bool.isRequired,
    onRefuse: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    cgu: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)).isRequired,
}

export default ModalCGU