import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getPayload } from 'utils/ActionUtils'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import HomeAction from 'pages/offline/actions/HomeAction'

const AuthenticatedRoute = (props) => {
    const dispatch = useDispatch()
    const payload = getPayload()
    if (!payload) {
        return <Redirect to='/home' />
    }
    const exp = moment(JSON.parse(payload).exp)
    if (moment().isAfter(exp)) {
        dispatch(HomeAction.logout())
        return <Redirect to='/home' />
    }
    return <Route {...props} />
}

AuthenticatedRoute.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.func,
}

export default AuthenticatedRoute