import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_STATISTICS,
} from '../constants/AccountConstants'
import DtoAccountStatistic from '../dto/DtoAccountStatistic'
import DtoUser from '../dto/DtoUser'

export const store = {
    accountUser: {},
    accountStatistics: [],
}

export function AccountReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ACCOUNT:
            return { ...state, accountUser: new DtoUser(action.accountUser) }
        case RECEIVE_ACCOUNT_STATISTICS:
            return {
                ...state,
                accountStatistics: action.accountStatistics.map(
                    (el) => new DtoAccountStatistic(el),
                ),
            }
        default:
            return state
    }
}
