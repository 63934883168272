import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { CustomerActionConstant } from 'reducers/CustomerReducer'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from '../utils/ActionUtils'

const CustomerAction = {
    fetchCustomers: () => (dispatch) =>
        genericPromise(ApplicationConf.customers.customers(), 'GET')
            .then((result) => {
                dispatch(CustomerActionConstant.receiveCustomers(result))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de la récupération des données : Liste des clients : ${err}`))
                ToastrAction.error('Erreur lors de la récupération des données : Liste des clients')
            }),

    deleteCustomer: (id) => (dispatch) => {
        return genericPromise(ApplicationConf.customers.customer(id), 'DELETE')
            .then(() => {
                ToastrAction.success('L\'élément a été supprimé')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur de suppression : Liste des clients : ${err}`))
                ToastrAction.error('Erreur de suppression : Liste des clients')
            })
    },
    updateCustomer: (id, updateCustomer) => (dispatch) =>
        genericPromise(ApplicationConf.customers.customer(id), 'PUT', updateCustomer)
            .then(() => {
                ToastrAction.success('L\'élément a été modifié')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur de mise à jour : Liste des clients : ${err}`))
                ToastrAction.error('Erreur de mise à jour : Liste des clients')
            }),

    createCustomer: (createCustomer) => (dispatch) =>
        genericPromise(ApplicationConf.customers.customers(), 'POST', createCustomer)
            .then(() => {
                ToastrAction.success('L\'élément a été ajouté')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de l'ajout : Liste des clients : ${err}`))
                ToastrAction.error('Erreur lors de l\'ajout : Liste des clients')
            }),
    createCustomerLicence: (id, licenceId, infoCompl) => (dispatch) =>
        genericPromise(ApplicationConf.customers.customerLicence(id, licenceId), 'POST', infoCompl)
            .then(() => {
                ToastrAction.success('La licence a été ajouté')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de l'ajout de licence : ${err}`))
                ToastrAction.error('Erreur lors de l\'ajout de licence')
            }),
    getCustomerLicence: (id) => (dispatch) =>
        genericPromise(ApplicationConf.customers.customerGetLicence(id), 'GET')
            .then((result) => {
                dispatch(CustomerActionConstant.receiveCustomersLicences(result))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de la récupération des données : Liste des Licences de clients : ${err}`))
                ToastrAction.error('Erreur lors de la récupération des données : Liste des Licences de clients')
            }),
    deleteCustomerLicence: (id, licenceId) => (dispatch) =>
        genericPromise(ApplicationConf.customers.customerLicence(id, licenceId), 'DELETE')
            .then(() => {
                ToastrAction.success('La licence a été supprimé')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de la suppression des données : Liste des Licences de clients : ${err}`))
                ToastrAction.error('Erreur lors de la suppression des données : Liste des Licences de clients')
            }),
}

export default CustomerAction
