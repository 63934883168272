import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { hasValue } from '../../utils/NumberUtil'
import { deburr, lowerCase } from 'lodash'

const H6 = (props) => (
    <h6 {...props} style={{ wordBreak: 'break-word' }}>{props.children}</h6>
)

class DisplayedValue extends Component {
    getValueDisplayedBelow = () => {
        const values = this.props.value && this.props.value.split ? this.props.value.split('\n').map(v => <H6>{ v }</H6>) : <H6>{ this.props.value }</H6>
        return (
            <div>
                <H6 className='bold'>{this.props.label ? `${this.props.label} :` : null}</H6>
                { values }
            </div>
        )
    }

    getValue = () => {
        if (this.props.obligatory) {
            return <H6 ><span className='bold'>{ this.props.label ? [this.props.label, ' : '] : null }<span className='primary-color-text'> * </span></span>{ hasValue(this.props.value) ? this.props.value : <span className='padding-left-6' /> }</H6>
        }
        return <H6 id={deburr(lowerCase(this.props.label)).replaceAll(' ', '_')}><span className='bold'>{ this.props.label ? [this.props.label, ' : '] : null }</span>{ hasValue(this.props.value) ? this.props.value : <span className='padding-left-6' /> }</H6>
    }

    onClick = () => {
        if (this.props.tableEditable) {
            this.props.onClick()
        }
    }

    render() {
        if (this.props.hideNull && !hasValue(this.props.value)) {
            return null
        }
        return (
            <div className='row no-margin' onClick={ this.onClick } id={deburr(lowerCase(this.props.label)).replaceAll(' ', '_')}>
                { this.props.displayValueBelow ? this.getValueDisplayedBelow() : this.getValue() }
                {/* <H6 className='bold'>{ this.props.label + ' :' }</H6>*/}
                {/* { values }*/}
                {/* <H6 ><span className='bold'>{ this.props.label + ' : ' }</span>{ this.props.value }</H6>*/}
            </div>
        )
    }
}

H6.propTypes = {
    children: PropTypes.string,
}

DisplayedValue.propTypes = {
    label: PropTypes.string,
    value: PropTypes.object,
    obligatory: PropTypes.bool,
    hideNull: PropTypes.bool,
    displayValueBelow: PropTypes.bool,
    tableEditable: PropTypes.bool,
    onClick: PropTypes.func,
}

export default DisplayedValue
