import Card from 'components/card/Card'
import React, { useState, useEffect } from 'react'
import { Grid, Icon } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CustomerTypeAction from 'licence/CustomerTypeAction'
import PropTypes from 'prop-types'
import '../../css/table.css'
import CustomerTypePopin from 'components/popin/CustomerTypePopin'
import useTitle from '../../utils/customHook/useTitle'
import App from 'pages/online/components/App'
import VirtualizedTable from '../../components/datatable/virtualizedTable/VirtualizedTable'
import { isUndefined } from 'lodash'
import PopinToConfirm from '../../components/popin/ValidationPopin'


const ListCustomerTypePanel = () => {
    const dispatch = useDispatch()

    const [isOpen, setOpen] = useState(false)

    const [isOpenValidationPopin, setOpenValidationPopin] = useState(false)

    const [idCustomerToDelete, setIdCustomerToDelete] = useState()

    useTitle(() => [{
        title: 'Liste des types de clients',
        href: 'ListCustomerType',
    }], [])

    useEffect(() => {
        dispatch(CustomerTypeAction.fetchCustomersType())
    }, [dispatch])

    const { customersType } = useSelector(
        (store) => ({
            customersType: store.CustomerTypeReducer.customersType,
        }),
        shallowEqual
    )

    const [customerType, setCustomerType] = useState({})

    const deleteCustomerType = () => {
        if (!isUndefined(idCustomerToDelete)) {
            dispatch(CustomerTypeAction.deleteCustomerType(idCustomerToDelete))
                .then(() => dispatch(CustomerTypeAction.fetchCustomersType()))
        }
    }

    const openPopinToConfirmDeletion = (elementToDelete) => {
        setIdCustomerToDelete(elementToDelete.id)
        setOpenValidationPopin(true)
    }

    const updateCustomerType = (customerTypeRow) => {
        setCustomerType(customerTypeRow)
        setOpen(true)
    }

    const handleClick = () => {
        setCustomerType({})
        setOpen(true)
    }

    const data = customersType.map((el) => {
        return {
            delete: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer', color: 'red' }} title={'Supprimer'} onClick={() => {
                openPopinToConfirmDeletion(el)
            }}
            >delete</Icon> },
            edit: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer' }} title={'Modifier'} onClick={() => {
                updateCustomerType(el)
            }}
            >edit</Icon> },
            id: { value: el.id },
            name: { value: el.name },
        }
    })

    return (
        <Grid>
            <App handleClick={handleClick} data={customersType}/>
            {customersType.length && (
                <>
                    <Card cardStyle={{ marginTop: window.innerHeight*0.1 }} maxWidth={window.innerWidth*0.6}>
                        <VirtualizedTable
                            style={{ marginTop: '10px' }}
                            rowHeight={30}
                            columnWidth={200}
                            data={data}
                            headers={[
                                'delete',
                                'edit',
                                'id',
                                'name',
                            ]}
                        />
                    </Card>

                    <CustomerTypePopin
                        isOpen={isOpen}
                        title={customerType.id && 'Modifier le type de client' || 'Ajouter un type de client'}
                        closePopin={() => setOpen(false)}
                        customerType={customerType}
                    />

                    <PopinToConfirm
                        onValidate={deleteCustomerType}
                        isOpen={isOpenValidationPopin}
                        closePopin={() => setOpenValidationPopin(false)}
                        title={'Confirmer la suppression'}
                        content={'Êtes-vous sûr de vouloir supprimer ce type de client ?'}
                    />
                </>
            )}
        </Grid>
    )
}

ListCustomerTypePanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    close: PropTypes.func,
}

export default ListCustomerTypePanel