/* eslint-disable no-process-env */
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import WaitReducer from 'wait/WaitReducer'
import { HomeReducer, store as HomeReducerStore } from '../pages/offline/reducers/HomeReducer'
import { AccountReducer, store as AccountReducerStore } from '../pages/online/account/reducers/AccountReducer'
import { ContactReducer, store as ContactReducerStore } from '../pages/online/contact/reducers/ContactReducer'
import { CmsReducer, store as CmsReducerStore } from '../pages/online/cms/reducers/CmsReducer'
import { store as ToastrReducerStore, ToastrReducer } from 'toastr/reducers/ToastrReducer'
import LicenceReducer, { initialState as LicenceReducerStore } from '../reducers/LicenceReducer'
import ContactsReducer, { initialState as ContactsReducerStore } from '../reducers/ContactsReducer'
import CustomerTypeReducer, { initialState as CustomerTypeReducerStore } from '../reducers/CustomerTypeReducer'
import CustomerReducer, { initialState as CustomerReducerStore } from '../reducers/CustomerReducer'
import ProjectManagerReducer, { initialState as ProjectManagerReducerStore } from '../reducers/ProjectManagerReducer'
import ContactTypeReducer, { initialState as ContactTypeReducerStore } from '../reducers/ContactsReducer'
import CustomerLicenceReducer, { initialState as CustomerLicenceReducerStore } from '../reducers/CustomerReducer'

export const history = createHashHistory()

const middlewares = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(logger)
}

const enhancer = applyMiddleware(...middlewares)

const initialStore = {
    HomeReducer: HomeReducerStore,
    AccountReducer: AccountReducerStore,
    ContactReducer: ContactReducerStore,
    CmsReducer: CmsReducerStore,
    ToastrReducer: ToastrReducerStore,
    WaitReducer: {
        isWait: false,
        name: 'ball-grid-pulse',
        color: 'blue',
        fadeIn: 'none',
    },
    LicenceReducer: LicenceReducerStore,
    ContactsReducer: ContactsReducerStore,
    ContactTypeReducer: ContactTypeReducerStore,
    CustomerTypeReducer: CustomerTypeReducerStore,
    CustomerReducer: CustomerReducerStore,
    CustomerLicenceReducer: CustomerLicenceReducerStore,
    ProjectManagerReducer: ProjectManagerReducerStore,
}

const appReducers = combineReducers({
    HomeReducer,
    AccountReducer,
    ContactReducer,
    CmsReducer,
    ToastrReducer,
    WaitReducer,
    router: connectRouter(history),
    LicenceReducer,
    ContactsReducer,
    ContactTypeReducer,
    CustomerTypeReducer,
    CustomerReducer,
    CustomerLicenceReducer,
    ProjectManagerReducer,
})

const rootReducer = (state, action) => {
    if (action.type === 'RESET_ALL_STORE') {
        const { applicationSettings } = state.HomeReducer
        const homeReducer = {
            ...initialStore.HomeReducer,
            applicationSettings,
        }
        return {
            ...state,
            ...initialStore,
            HomeReducer: homeReducer,
        }
    }
    return appReducers(state, action)
}

const AppStore = createStore(rootReducer, initialStore, enhancer)

export default AppStore
