export default class DtoContact {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.address = obj.address
        this.phone = obj.phone
        this.fax = obj.fax
        this.phoneOffice = obj.phoneOffice
        this.cellphone = obj.cellphone
        this.mail = obj.mail
        this.contactTitle = obj.contactTitle
        this.comment = obj.comment
        this.civility = obj.civility
        this.updateDate = obj.updateDate
        this.countryCode = obj.countryCode
        this.contactFunction = obj.contactFunction
        this.city = obj.city
        this.typeContact = obj.typeContact
        this.typeContactId = obj.typeContactId
    }
}