import { createSlice } from '@reduxjs/toolkit'
import DtoContact from 'administration/user/dto/DtoContact'
import DtoContactType from '../administration/user/dto/DtoContactType'

export const initialState = {
    contacts: [],
    contactsType: [],
}

const store = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        receiveContacts: (state, action) => {
            state.contacts = action.payload.map(p => new DtoContact(p))
        },
        receiveContactsType: (state, action) => {
            state.contactsType = action.payload.map(p => new DtoContactType(p))
        },
    },
})

export const ContactActionConstant = store.actions
export default store.reducer
