import React, { useCallback, useEffect, useState } from 'react'
import { DialogMUI, DialogTitleMUI, DialogContentMUI, DialogActionsMUI } from 'components/styled/Dialog'
import { Button, Grid, IconButton, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import DtoCustomerType from 'administration/user/dto/DtoCustomerType'
import CustomerTypeAction from 'licence/CustomerTypeAction'
import { useDispatch } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'
import Input from '../forms/Input'


const CustomerTypePopin = ({
    title = '',
    isOpen = false,
    customerType = {},
    closePopin = () => {},
}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState()

    useEffect(() => {
        setName(customerType.name)
    }, [customerType, isOpen])

    const create = useCallback(() => {
        const createdCustomerType = { name }
        if (!name || name.trim() === '') {
            ToastrAction.error('Veuillez saisir un type de client')
        } else {
            dispatch(CustomerTypeAction.createCustomerType(new DtoCustomerType(createdCustomerType)))
                .then(() => {
                    dispatch(CustomerTypeAction.fetchCustomersType())
                })
                .finally(closePopin)
        }
    }, [name, closePopin, dispatch])

    const update = useCallback(() => {
        const UpdatedCustomerType = { name }
        if (name.trim() === '') {
            ToastrAction.error('Erreur de mise à jour : un nom ne peut pas être vide')
        } else {
            dispatch(CustomerTypeAction.updateCustomerType(customerType.id, new DtoCustomerType(UpdatedCustomerType)))
                .then(() => {
                    dispatch(CustomerTypeAction.fetchCustomersType())
                })
                .finally(closePopin)
        }
    }, [name, customerType.id, closePopin, dispatch])

    return (
        <DialogMUI open={isOpen} onClose={closePopin} maxWidth='xs' PaperProps={{ sx: { maxHeight: '50vh' } }}>
            <DialogTitleMUI>
                {title}
                <IconButton
                    aria-label={'Fermer'}
                    onClick={closePopin}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white',
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
                    <Grid item marginTop={2}>
                        <Input
                            value={name}
                            placeholder={'Nom'}
                            onChange={setName}
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Tooltip title={'Sauvegarder'}>
                    <Button
                        variant='contained'
                        component='label'
                        autoFocus
                        onClick={customerType.id ? update : create}
                    >
                        {customerType.id ? 'Modifier' : 'Créer'}
                    </Button>
                </Tooltip>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

CustomerTypePopin.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    customerType: PropTypes.shape({}),
}

export default CustomerTypePopin