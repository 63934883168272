// Choisir une des deux usedUrl ci dessous

// A utiliser pour le serveur
const url = 'https://aqua6bo2.aquadb.fr/licences/'
const myUrl = 'https://aqua6bo2.aquadb.fr/licences/'


// ////////////////////////////////////////////////////////////
const path = `${url}${url.endsWith('/') ? '' : '/'}`
const contentsPath = `${url}${url.endsWith('/') ? '' : '/'}contents/`
const cmsPath = `${contentsPath}CMS/`

export { path, contentsPath, cmsPath, url, myUrl }
