import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AppStore from 'store/AppStore'
import Spinner from '../progress/Spinner'


class Card extends Component {
    getCardContent = () => {
        const { cardContentStyle, round, title } = this.props
        const styleContent = this.props.maxHeight ?
            { maxHeight: `${this.props.maxHeight}px`, ...cardContentStyle } :
            (this.props.height ? { height: `${this.props.height}px`, ...cardContentStyle } : { ...cardContentStyle })
        return (
            <div
                className={`card-content no-padding ${this.props.greyContent ? 'grey-background ' : ''}${this.props.contentClassName ? this.props.contentClassName : ''}`}
                style={round ? { borderRadius: `${!title ? '5px' : '0 0 5px 5px'}`, ...styleContent } : styleContent}
            >
                { this.props.children }
            </div>
        )
    }

    getCardTitle = () => {
        const { headerStyle, actionsComponents } = this.props
        // const actions = this.props.actions.length ?
        //     <MoreVert links={ this.props.actions } active={ this.props.active }/> : null
        const actions = null
        if (this.props.link && this.props.displayWhen) {
            return (
                <div
                    className={`card-title clickable ${this.props.withMargin ? 'margin-bottom-30' : ''}`}
                    style={this.props.round ? { borderRadius: '5px 5px 0 0', ...headerStyle } : { ...headerStyle }}
                    onClick={() => AppStore.dispatch(push(this.props.link))}
                >
                    <div className='row no-margin valign-wrapper' style={{ display: 'inline-block', width: '100%' }}>
                        { this.props.title }
                        { actions }

                        <div className='right'>
                            {actionsComponents}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div
                className={`card-title ${this.props.withMargin ? 'margin-bottom-30 ' : 'no-margin '}${this.props.active ? 'active' : 'card-disabled'}`}
                style={this.props.round ? { borderRadius: '5px 5px 0 0', ...headerStyle, display: 'inline-block', width: '100%' } : { ...headerStyle, display: 'inline-block', width: '100%' }}
                onClick={this.props.onClickTitle}
            >
                {this.props.title}
                {actions}

                <div className='right'>
                    {actionsComponents}
                </div>
            </div>
        )
    }

    getStyles = () => {
        const maxes = this.props.maxWidth || this.props.maxHeight ? {
            maxWidth: this.props.maxWidth ? (`${this.props.maxWidth}px`) : ('auto'),
            maxHeight: this.props.maxHeight ? (`${this.props.maxHeight}px`) : ('auto'),
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: 0,
        } : null

        const boxShadow = this.props.noBoxShadow ? { 'box-shadow': '0 0' } : null
        return {
            ...maxes,
            ...boxShadow,
        }
    }

    getClassName = () => {
        const maxHeight = this.props.maxHeight ? 'scrollable-card ' : ''
        const maxWidth = this.props.maxWidth ? '' : (this.props.noMargin ? 'no-margin ' : '')
        const smallCard = this.props.smallCard ? 'smallCard ' : ''

        return `card ${maxHeight}${maxWidth}${smallCard}${this.props.className}`
    }

    render() {
        const { cardStyle, title, displayWhen } = this.props
        if (this.props.displayIf) {
            return (
                <div
                    className={ this.getClassName() }
                    style={this.props.round ? { borderRadius: 5, ...this.getStyles(), ...cardStyle } : { ...this.getStyles(), ...cardStyle } }
                    data-cy={this.props['data-cy']}
                >
                    { title ? this.getCardTitle() : null }
                    { displayWhen ? this.getCardContent() : <Spinner/> }
                </div>
            )
        }
        return null
    }
}

Card.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: PropTypes.element.isRequired,
    withMargin: PropTypes.bool,
    displayIf: PropTypes.bool,
    displayWhen: PropTypes.bool,
    link: PropTypes.string,
    actions: PropTypes.arrayOf(PropTypes.object),
    actionsComponents: PropTypes.arrayOf(PropTypes.element),
    active: PropTypes.bool,
    height: PropTypes.number,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    greyContent: PropTypes.bool,
    noMargin: PropTypes.bool,
    smallCard: PropTypes.bool,
    headerStyle: PropTypes.object,
    noBoxShadow: PropTypes.bool,
    cardStyle: PropTypes.shape({}),
    cardContentStyle: PropTypes.shape({}),
    round: PropTypes.bool,
    onClickTitle: PropTypes.func,

    'data-cy': PropTypes.string,
}

Card.defaultProps = {
    withMargin: false,
    displayIf: true,
    displayWhen: true,
    actions: [],
    active: true,
    className: '',
    children: null,
    contentClassName: '',
    noMargin: true,
    headerBackground: '',
    noBoxShadow: false,
    onClickTitle: () => {},
}

export default Card
