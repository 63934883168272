import Card from 'components/card/Card'
import React, { useEffect, useState } from 'react'
import { Grid, Icon } from '@mui/material'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContactAction from 'licence/ContactAction'
import PropTypes from 'prop-types'
import '../../css/table.css'
import ContactPopin from 'components/popin/ContactPopin'
import useTitle from '../../utils/customHook/useTitle'
import App from 'pages/online/components/App'
import VirtualizedTable from '../../components/datatable/virtualizedTable/VirtualizedTable'
import moment from 'moment/moment'
import PopinToConfirm from '../../components/popin/ValidationPopin'
import { isUndefined } from 'lodash'


const ListContactPanel = () => {
    const dispatch = useDispatch()

    const [isOpen, setOpen] = useState(false)

    const [isOpenValidationPopin, setOpenValidationPopin] = useState(false)

    const [idContactToDelete, setIdContactToDelete] = useState()

    useTitle(() => [{
        title: 'Liste des contacts',
        href: 'ListContact',
    }], [])

    useEffect(() => {
        dispatch(ContactAction.fetchContacts())
    }, [dispatch])

    const { contacts } = useSelector(
        (store) => ({
            contacts: store.ContactsReducer.contacts,
        }),
        shallowEqual
    )

    const [contact, setContact] = useState({})

    const deleteContact = () => {
        if (!isUndefined(idContactToDelete)) {
            dispatch(ContactAction.deleteContact(idContactToDelete))
                .then(() => dispatch(ContactAction.fetchContacts()))
        }
    }

    const openPopinToConfirmDeletion = (elementToDelete) => {
        setIdContactToDelete(elementToDelete.id)
        setOpenValidationPopin(true)
    }

    const updateContact = (contactRow) => {
        setContact(contactRow)
        setOpen(true)
    }

    const handleClick = () => {
        setContact({})
        setOpen(true)
    }


    const data = contacts.map((el) => {
        return {
            delete: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer', color: 'red' }} title={'Supprimer'} onClick={() => {
                openPopinToConfirmDeletion(el)
            }}
            >delete</Icon> },
            edit: { value: <Icon fontSize={'large'} style={{ cursor: 'pointer' }} title={'Modifier'} onClick={() => {
                updateContact(el)
            }}
            >edit</Icon> },
            civility: { value: el.civility },
            name: { value: el.name },
            function: { value: el.contactFunction },
            city: { value: el.city },
            address: { value: el.address },
            phone: { value: el.phone },
            cellPhone: { value: el.cellphone },
            email: { value: el.mail },
            comment: { value: el.comment },
            updateDate: { value: moment.unix(el.updateDate).format('DD/MM/YYYY') },
            typeContact: { value: el.typeContact },
            typeContactId: { value: el.typeContactId },
        }
    })

    return (
        <Grid>
            <App handleClick={handleClick} data={contacts} />
            {contacts.length && (
                <>
                    <Card maxWidth={window.innerWidth*0.9} cardStyle={{ marginTop: window.innerHeight*0.1 }}>
                        <VirtualizedTable
                            style={{ marginTop: '10px' }}
                            data={data}
                            headers={[
                                'delete',
                                'edit',
                                'civility',
                                'name',
                                'function',
                                'city',
                                'address',
                                'phone',
                                'cellPhone',
                                'email',
                                'comment',
                                'updateDate',
                                'typeContact',
                                'typeContactId',
                            ]}
                            rowHeight={30}
                            columnWidth={150}
                        />
                    </Card>


                    <ContactPopin
                        isOpen={isOpen}
                        title={contact.id && 'Modifier le contact' || 'Ajouter un contact'}
                        closePopin={() => setOpen(false)}
                        contact={contact}
                    />

                    <PopinToConfirm
                        onValidate={deleteContact}
                        isOpen={isOpenValidationPopin}
                        closePopin={() => setOpenValidationPopin(false)}
                        title={'Confirmer la suppression'}
                        content={'Êtes-vous sûr de vouloir supprimer ce contact ?'}
                    />
                </>
            )}
        </Grid>
    )
}

ListContactPanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    closePopin: PropTypes.func,
}

export default ListContactPanel