import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { CustomerTypeActionConstant } from 'reducers/CustomerTypeReducer'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from '../utils/ActionUtils'

const CustomerTypeAction = {
    fetchCustomersType: () => (dispatch) =>
        genericPromise(ApplicationConf.customersType.customersType(), 'GET')
            .then((result) => {
                dispatch(CustomerTypeActionConstant.receiveCustomersType(result))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de la récupération des données : Liste des types de clients : ${err}`))
                ToastrAction.error('Erreur lors de la récupération des données : Liste des types de clients')
            }),

    deleteCustomerType: (id) => (dispatch) =>
        genericPromise(ApplicationConf.customersType.customerType(id), 'DELETE')
            .then(() => {
                ToastrAction.success('L\'élément a été supprimé')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur de suppression : Liste des types de clients : ${err}`))
                ToastrAction.error('Erreur de suppression : Liste des types de clients')
            }),

    updateCustomerType: (id, updateCustomerType) => (dispatch) =>
        genericPromise(ApplicationConf.customersType.customerType(id), 'PUT', updateCustomerType)
            .then(() => {
                ToastrAction.success('L\'élément a été modifié')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur de mise à jour : Liste des types de clients : ${err}`))
                ToastrAction.error('Erreur de mise à jour : Liste des types de clients')
            }),

    createCustomerType: (createCustomerType) => (dispatch) =>
        genericPromise(ApplicationConf.customersType.customersType(), 'POST', createCustomerType)
            .then(() => {
                ToastrAction.success('L\'élément a été ajouté')
            })
            .catch((err) => {
                dispatch(LogAction.logError(`Erreur lors de l'ajout : Liste des types de clients : ${err}`))
                ToastrAction.error('Erreur lors de l\'ajout : Liste des types de clients')
            }),
}

export default CustomerTypeAction
