const options = {
    progressBar: true,
    preventDuplicates: true,
    hideDuration: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'slideDown',
    hideMethod: 'slideUp',
}

const ToastrAction = {
    success(message, timeOut) {
        window.toastr.options = { ...options, timeOut }
        window.toastr.success(message, '')
    },
    error(message, timeOut) {
        window.toastr.options = { ...options, timeOut }
        window.toastr.error(message, '')
    },
    info(message, timeOut) {
        window.toastr.options = { ...options, timeOut }
        window.toastr.info(message, '')
    },
    warning(message, timeOut) {
        window.toastr.options = { ...options, timeOut }
        window.toastr.warning(message, '')
    },
}


export default ToastrAction