import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore, { history } from './store/AppStore'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { ThemeProvider } from '@mui/material/styles'
import fr from 'assets/i18n/properties_fr.json'
import en from 'assets/i18n/properties_en.json'
import Login from './pages/offline/Login'
import App from './pages/online/components/App'
import Home from './pages/online/home/Home'
import { mainTheme } from 'components/styled/Theme'
import Password from 'pages/offline/Password'
import { CssBaseline } from '@mui/material'
import AuthenticatedRoute from './AuthenticatedRoute'
import BoundaryError from 'log/components/BoundaryError'
import envFile from '.env'
import ListLicencePanel from 'pages/offline/ListLicence'
import ListContactPanel from 'pages/offline/ListContact'
import ListCustomerTypePanel from 'pages/offline/ListCustomerType'
import ListCustomerPanel from 'pages/offline/ListCustomer'
import ListContactTypePanel from 'pages/offline/ListContactType'
import ListProductPanel from './pages/offline/ListProduct'

i18n.addLang(fr, ['fr', 'FR'])
i18n.addLang(en, ['en', 'EN'])
i18n.addLang(fr, 'default')
i18n.init()

process.envs = {}

fetch(envFile)
    .then(response => response.text())
    .then(text => {
        text.split('\n')
            .filter((env) => env && env.length && env.includes('='))
            .forEach((val) => {
                process.envs[val.split('=')[0]] = val.split('=')[1]
            })
    }).catch(() => {})

const properties = [
    {
        code: 'fr',
        value: fr,
    },
    {
        code: 'en',
        value: en,
    },
]

export const handleUpdateLanguage = (code) => {
    const propertie = (properties.find(p => p.code === code) || {})
    i18n.clear()
    i18n.addLang(propertie.value || fr, 'default')
    i18n.init()
}

const onDeviceReady = () => {
    window.open = window.cordova.InAppBrowser.open
}

document.addEventListener('deviceready', onDeviceReady, false)

render(
    <ThemeProvider theme={mainTheme}>
        <CssBaseline />
        <Provider store={AppStore}>
            <ConnectedRouter history={history}>
                <BoundaryError>
                    <Switch>
                        <Route exact path='/login' component={(props) => <Login {...props} />} />
                        <Route exact path='/reset' component={(props) => <Password {...props} />} />
                        <Route path='/' component={(props) => <AuthenticatedRoute {...props} component={App} />} />
                    </Switch>
                    <Route exact path='/home' component={(props) => <Home {...props} />} />
                    <Route exact path='/listLicence' component={(props) => <ListLicencePanel {...props} />} />
                    <Route exact path='/listContact' component={(props) => <ListContactPanel {...props} />} />
                    <Route exact path='/listCustomerType' component={(props) => <ListCustomerTypePanel {...props} />} />
                    <Route exact path='/listCustomer' component={(props) => <ListCustomerPanel {...props} />} />
                    <Route exact path='/listContactType' component={(props) => <ListContactTypePanel {...props}/>}/>
                    <Route exact path='/listProduct' component={(props) => <ListProductPanel {...props}/>}/>
                </BoundaryError>
            </ConnectedRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('app')
)
