'use strict'
import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
import ToastrAction from 'toastr/actions/ToastrAction'
import ApplicationConf from '../../../conf/ApplicationConf'
import AppStore from '../../../store/AppStore'
import { checkAuth, checkAuthV2, checkError, getAuthorization, getAuthorizationLogin, getJson, removeToken } from '../../../utils/ActionUtils'
import { DISCONNECTED, RECEIVE_APPLICATION_SETTINGS, RECEIVE_CGU_CMS, RECEIVE_CMS_HOME, RECEIVE_CMS_LOGO, RECEIVE_DATE_VALID_CGU, RECEIVE_PARTENAIRE_CMS, SET_RELOAD, SIEAU_LOGIN, SIEAU_TOKEN, TITLE } from '../constants/HomeConstants'
import WaitAction from 'wait/WaitAction'
import LogAction from 'log/actions/LogAction'
import DtoCMSEvent from 'pages/online/cms/dto/DtoCMSEvent'
import AccountAction from 'pages/online/account/actions/AccountAction'

const HomeAction = {
    disconnected: message => ({
        type: DISCONNECTED,
        message,
    }),
    login: (login, password, onLogin) => dispatch => {
        dispatch(WaitAction.waitStart())
        const basicAuth = btoa(`${login}:${password}`)
        return fetch(ApplicationConf.login(), {
            method: 'POST',
            headers: {
                Authorization: `Basic ${basicAuth}`,
            },
            body: JSON.stringify({
                module: 'WEB',
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                localStorage.setItem(SIEAU_LOGIN, login)
                localStorage.setItem(SIEAU_TOKEN, json)
                dispatch(WaitAction.waitStop())
                dispatch(AccountAction.fetchUser(login, onLogin))
            }).catch(err => {
                dispatch(LogAction.logError(`Vous n'êtes pas autorisé à vous connecter : ${err}`))
                dispatch(ToastrAction.error('Vous n\'êtes pas autorisé à vous connecter'))
                dispatch(WaitAction.waitStop())
            })
    },
    logout() {
        return (dispatch) => {
            return fetch(ApplicationConf.logout(), {
                method: 'POST',
                headers: getAuthorization(),
            }).then((response) => {
                removeToken()
                localStorage.removeItem(SIEAU_TOKEN)
                dispatch(push('/login'))
                switch (response.status) {
                    case 200:
                        break
                    default:
                        throw new Error(response.statusText)
                }
            })
        }
    },

    verifyResetCode(login, resetCode) {
        return (dispatch) => {
            return fetch(ApplicationConf.verifyResetCode(), {
                method: 'POST',
                body: JSON.stringify({ login, resetCode }),
            })
                .then(response => checkAuthV2(response, {
                    404: () => {
                        dispatch(ToastrAction.error('Impossible de réinitialiser le mot de passe'))
                        return false
                    },
                    429: () => {
                        dispatch(ToastrAction.error('Erreur 429'))
                        return false
                    },
                    200: () => {
                        dispatch(ToastrAction.success('Code valide'))
                        return true
                    },
                }))
                .then(response => {
                    return response
                })
                .catch(e => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : Retrouver mon mot de passe : ${e}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données : Retrouver mon mot de passe'))
                })
        }
    },

    resetPassword(login, redirectLogin = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'POST',
                body: JSON.stringify({ login, module: 'MOBILE' }),
            })
                .then(checkError)
                .then((json) => {
                    if (json.status !== 200) {
                        dispatch(ToastrAction.error('ID incorrect', true))
                        return json
                    }
                    if (redirectLogin) {
                        dispatch(push('/login'))
                    }
                    return null
                })
                .catch((e) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : Retrouver mon mot de passe : ${e}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données : Retrouver mon mot de passe', true))
                })
        }
    },

    setTitle(title) {
        const storeTitleHash = AppStore.getState().HomeReducer.title.map(obj => obj.title).join()
        const titleHash = title.map(obj => obj.title).join()
        if (storeTitleHash !== titleHash) {
            return { type: TITLE, title }
        }
        return { type: TITLE, title }
    },

    receiveApplicationSettings(applicationSettings) {
        return { type: RECEIVE_APPLICATION_SETTINGS, applicationSettings }
    },

    promiseApplicationSettings() {
        return fetch(ApplicationConf.user.applicationSettings(), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchApplicationSettings() {
        return (dispatch) => {
            return HomeAction.promiseApplicationSettings()
                .then(json => {
                    dispatch(HomeAction.receiveApplicationSettings(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : Paramétrages : ${err}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données : Paramétrages'))
                })
        }
    },

    receiveCMSHome(cmsHome) {
        return { type: RECEIVE_CMS_HOME, cmsHome }
    },

    fetchCMSHome(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.get(id), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(checkAuth)
                .then(checkError)
                .then((json) => {
                    dispatch(HomeAction.receiveCMSHome(json))
                    return new DtoCMSEvent(json)
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : Article : ${err}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données : Article'))
                })
        }
    },

    receiveCMSLogo(cmsLogo) {
        return { type: RECEIVE_CMS_LOGO, cmsLogo }
    },

    promiseCMSLogo(id) {
        return fetch(ApplicationConf.cms.get(id), {
            method: 'GET',
            headers: getAuthorizationLogin(),
        })
            .then(checkAuth)
            .then(checkError)
    },

    fetchCMSLogo(id = 3) {
        return (dispatch) => {
            return HomeAction.promiseCMSLogo(id)
                .then((json) => {
                    dispatch(HomeAction.receiveCMSLogo(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : Article : ${err}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données : Article'))
                })
        }
    },

    receiveCGUFromCms(cmsCGU) {
        return { type: RECEIVE_CGU_CMS, cmsCGU }
    },

    getCGUFromCms() {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(2), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCGUFromCms(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : ${err}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données'))
                })
        }
    },

    updatePassword(password, token) {
        return (dispatch) => {
            return fetch(ApplicationConf.resetPassword(), {
                method: 'PUT',
                body: JSON.stringify({ password, token }),
            })
                .then(checkError)
                .catch(e => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : Nouveau mot de passe : ${e}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données : Nouveau mot de passe'))
                })
        }
    },

    receiveCmsPartenaire(cms) {
        return { type: RECEIVE_PARTENAIRE_CMS, cms }
    },

    getCmsPartenaire() {
        return (dispatch) => {
            return fetch(ApplicationConf.cms.getByCategory(2), {
                method: 'GET',
                headers: getAuthorizationLogin(),
            })
                .then(getJson)
                .then((json) => {
                    dispatch(HomeAction.receiveCmsPartenaire(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : ${err}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données'))
                })
        }
    },

    sendDateCgu(postDateCGU) {
        return (dispatch) => {
            return fetch(ApplicationConf.cgu.sendDateCgu(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(postDateCGU),
            }).catch((err) => {
                dispatch(LogAction.logError(`Erreur de création : ${err}`))
                dispatch(ToastrAction.error('Erreur de création'))
            })
        }
    },

    promiseDateValidCGU(login) {
        return fetch(ApplicationConf.cgu.getDateValidCGU(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    getDateValidCGU(login) {
        return (dispatch) => {
            return HomeAction.promiseDateValidCGU(login)
                .then((json) => {
                    dispatch(HomeAction.receiveDateValidCGU(json.date))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`Erreur lors de la récupération des données : ${err}`))
                    dispatch(ToastrAction.error('Erreur lors de la récupération des données'))
                })
        }
    },

    receiveDateValidCGU(date) {
        return { type: RECEIVE_DATE_VALID_CGU, date }
    },

    receiveReload(reload) {
        return { type: SET_RELOAD, reload }
    },

    setWindowReload(reload) {
        return (dispatch) => {
            dispatch(HomeAction.receiveReload(`{ reload: ${reload} }`))
        }
    },
}

export default HomeAction
